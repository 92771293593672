import styles from "./styles/product-introduce.module.scss"

const ProductIntroduce = (props: any) => {
    const screenData = props.screenData

    return (
        <div className={styles.component}>
            <div className={styles.descriptionSizing}>
                <div className={styles.description}>{screenData?.description}</div>
            </div>
            {
                <div className={styles.imagesContainer}>
                    {
                        screenData && screenData.image_urls?.length > 0 &&
                        screenData.image_urls?.map((url: string, url_index: number) => {
                            return (
                                <div className={styles.imageSizing} key={url_index}>
                                    <div className={`background-image ${styles.image}`}
                                         style={{backgroundImage: `url('${url}')`}}/>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}
export default ProductIntroduce