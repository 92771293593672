import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {PAGE_ENUM} from "../../enum/page-constrant";
import Page from "components/Page/Page";
import LineSlogan from "components/LineSlogan/LineSlogan";
import TopBannerComponent from "pages/Showroom/components/TopBanner/TopBannerComponent"
import {getScreenData} from "../../redux/actions/home-page";
import FactoryComponent from "pages/Showroom/components/Factory/FactoryComponent";
import ShowroomBannerComponent from "pages/Showroom/components/ShowroomBanner/ShowroomBannerComponent";
import ShowroomComponent from "pages/Showroom/components/Showroom/ShowroomComponent";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";

const Showroom = () => {
    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)

    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.showroom, PAGE_ENUM.website))
    }, [dispatch])

    const getScreenItem = (screen_item: number) => {
        const arr = store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screen_item}`)
        return arr
    }

    return (store?.homeScreen !== undefined && store?.homeScreen !== null ?
            <>
                <Page>
                    <Breadcrumb title={'Showroom'}/>
                    <TopBannerComponent screenData={getScreenItem(1)} />
                    <FactoryComponent screenData={getScreenItem(2)} />
                    <LineSlogan/>
                    <ShowroomBannerComponent screenData={getScreenItem(3)} />
                    <ShowroomComponent screenData={getScreenItem(4)} />
                </Page>
            </> : <></>
    )
}
export default Showroom