import React from 'react'
//styles
import styles from './styles/item-manufactoring.module.scss'
const ItemMenuFactoring=({imgVariable}:any)=>{
    return(
        <div className={styles.menuFactoring} >
            <img src={imgVariable} alt={""}/>
            <p>1. Cây trồng</p>
        </div>
    )
}
export  default ItemMenuFactoring;