import React from "react";
import styles from './styles/logo.module.scss'

const Logo = (props: any) => {
    const screenData = props?.screenData
    return (
        <div className={styles.logo}>
            <div className={`${styles.logoTitle}  align-items-center justify-content-center`}>
                <div className={styles.logoTitleContent}>CHỨNG NHẬN</div>
            </div>
            <div className={`${styles.logoContainer}  align-items-center justify-content-center`}>
                {
                    screenData && screenData.screen_item_details?.length > 0 &&
                    screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                        return (
                            <div className={styles.logoContainerImg} key={`cert_${index}`}>
                                {
                                    screen_item_detail.image_urls?.length > 0 &&
                                    screen_item_detail.image_urls?.map((url: string, url_index: number) => {
                                        return (
                                            <div className={styles.imageContainer} key={`img_${url_index}`}>
                                                <div className={`background-image ${styles.image}`} style={{backgroundImage: `url('${url}')`}}
                                                     key={url_index}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                <div className={styles.logoContainerLine}>

                </div>
            </div>
        </div>
    )
}
export default Logo;