import React from 'react'
import styles from './styles/navigation-on-phone.module.scss'

import imManufacturing from '../../../../../../assets/icons/ic-home-manufacture-connect.svg'
import icNext from '../../../../../../assets/icons/ic-next-slider.svg'
function NavigationOnPhone (props: any) {
    return (
        <div className={`on-phone ${styles.containerNavigation} ${props?.isShowNavigation ? styles.show : ''}`}>
            {
                props?.menuList.map((menu :any, index: number) => {
                    return (
                        <div className={styles.containerNavigationItem} key={index}>
                            <div className={styles.containerTitle}>
                                <div className={`${styles.navigationItem} ${window.location?.pathname === menu?.url ? styles.navigationItemActive : ''}`} key={index} onClick={() => {if (menu.url) { window.location.href = menu.url}}}>
                                    {menu?.label}
                                </div>
                            </div>
                            {
                                menu?.url === '/san-pham' &&
                                <div className={styles.container}>
                                    <div className={styles.containerManufacturing}>
                                        {
                                            menu?.sub_title_list && menu?.sub_title_list.length > 0 && menu?.sub_title_list.map((sub_title: any , indexSub: number) => {
                                                return (
                                                    <div className={styles.containerImage} key={indexSub} onClick={() => {if (sub_title.url) { window.location.href = sub_title.url}}}>
                                                        <div className={menu?.url === '/san-pham' ? styles.imageProduct : styles.imageManufacturing}>
                                                            <div className={`background-image ${styles.image}`} style={{backgroundImage: `url('${sub_title?.image_url}')`}}/>
                                                        </div>
                                                        {
                                                            !(menu?.url === '/san-pham') &&
                                                            <div className={`${styles.imageManufacturing}`}>
                                                                <div>
                                                                    <div
                                                                        className={`background-image ${styles.imageConnect}`}
                                                                        style={{backgroundImage: `url('${imManufacturing}')`}}/>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className={`background-image ${styles.icNext}`} style={{backgroundImage: `url('${icNext}')`}} onClick={() => {window.location.href='/san-pham'}}/>
                                </div>
                            }
                            {
                                menu?.url === '/cua-hang' &&
                                <div className={styles.container}>
                                    <div className={styles.containerStore}>
                                        {
                                            menu?.sub_title_list && menu?.sub_title_list.length > 0 && menu?.sub_title_list.map((store:any, index: number) => {
                                                return (
                                                    <div className={styles.store} key={index}>
                                                        <div className={`background-image ${styles.imageStore}`} style={{backgroundImage: `url('${store?.image_url}')`}}/>
                                                        <p className={styles.description}>{store?.content}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                menu?.type === 'mua-hang' &&
                                <div className={styles.container}>
                                    <div className={styles.containerShopping}>
                                        {
                                            menu?.sub_title_list && menu?.sub_title_list.length > 0 &&
                                            menu?.sub_title_list.map((item: any, index_list: number) => {
                                                return (
                                                    <div key={`shopping_${index_list}`} onClick={() => { if (item.url) { window.open(item.url, '_blank')}}} className={styles.dropdownShopping}>
                                                        <span className={styles.content}>{item?.content}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                    )
                })
            }
        </div>
    )
}

export default NavigationOnPhone