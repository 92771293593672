import styles from './styles/banner-home.module.scss'
import React, {useState} from "react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import {Swiper, SwiperSlide} from "swiper/react"
import icPlayVideo from "../../../../assets/icons/ic-play-video.webp";

const BannerHome = (props: any) => {
    const [isShow, setShow] = useState(false)

    const getImageURL = (item: any) => {
        let url = ''
        if (item) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    return (
        <section className={`${styles.banner}`}>
            {
                props?.bannerHome &&
                props?.bannerHome?.screen_item_details && props?.bannerHome?.screen_item_details?.length > 0 &&
                <Swiper>
                    {
                        props?.bannerHome?.screen_item_details.map((value: any, index: number) => (
                            <SwiperSlide key={index}>
                                <div className={styles.containerBanner}>
                                    {
                                        props?.bannerHome?.type === "image" &&
                                        <div className={`background-image ${styles.imagesSlider}`}
                                             style={{backgroundImage: `url('${getImageURL(value)}')`}}/>
                                    }
                                    {
                                        props?.bannerHome?.type === "video" &&
                                        <div className={styles.videoContainer}>
                                            <video id={`video_${index}`} src={value?.video_url ? value?.video_url : ''}
                                                   title={'Video Giới Thiệu Thương Hiệu'} autoPlay={false} onPause={() => {setShow(true)}}
                                                   onClick={() => {
                                                       const video = document.getElementById(`video_${index}`) as HTMLVideoElement | null
                                                       if (video !== undefined && video !== null) {
                                                           if (video.paused) {
                                                               video.play()
                                                               setShow(false)
                                                           } else {
                                                               video.pause()
                                                               setShow(true)
                                                           }
                                                       }
                                                   }}
                                            />
                                            <img className={`${styles.icPlay} ${isShow ? '' : styles.hide}`}
                                                 src={icPlayVideo} alt={''} onClick={() => {
                                                const video = document.getElementById(`video_${index}`) as HTMLVideoElement | null
                                                if (video !== undefined && video !== null) {
                                                    if (video.paused) {
                                                        video.play()
                                                        setShow(false)
                                                    } else {
                                                        video.pause()
                                                        setShow(true)
                                                    }
                                                }
                                            }}/>
                                        </div>
                                    }
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            }
        </section>
    )
}
export default BannerHome