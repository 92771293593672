import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import styles from './styles/slider.module.scss'
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import {Navigation} from "swiper";
import icNext from 'assets/icons/ic-next-coffee-bean.svg'
import icPre from 'assets/icons/ic-pre-coffee-bean.svg'

const Slider = (props: any) => {
    const screenData = props?.screenData
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)

    return (
        <div className={styles.slider}>
            <div ref={navigationPrevRef}
                 className={` ${styles.sliderPrevBtn} `}>
                <div className={` ${styles.slidePrevBtnIcons} background-image`}
                     style={{backgroundImage: `url(${icPre})`}}/>
            </div>
            <div ref={navigationNextRef}
                 className={`${styles.sliderNextBtn} `}>
                <div className={` ${styles.sliderNextBtnIcons} background-image`}
                     style={{backgroundImage: `url(${icNext})`}}/>
            </div>
            <>
                {
                    screenData && screenData.screen_item_details?.length > 0 &&
                    screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                        return (
                            <Swiper
                                key={`coffe_bean_gallery_${index}`}
                                slidesPerView={5}
                                spaceBetween={13}
                                slidesPerGroup={1}
                                loopFillGroupWithBlank={true}
                                loop={true}
                                autoplay={true}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current
                                }}
                                onInit={(swiper: any) => {
                                    const a: any = swiper.params.navigation
                                    a.prevEl = navigationPrevRef.current
                                    a.nextEl = navigationNextRef.current
                                    swiper.navigation.destroy()
                                    swiper.navigation.init()
                                    swiper.navigation.update()
                                }}
                                modules={[Navigation]}
                                breakpoints={{
                                    320: {

                                        slidesPerView: 2,
                                    },
                                    // when window width is >= 768px
                                    768: {

                                        slidesPerView: 5,
                                    },

                                }}
                                className={'mySwiperCoffeeBean'}
                            >
                                {
                                    screen_item_detail.image_urls?.length > 0 &&
                                    screen_item_detail.image_urls?.map((url: string, url_index: number) => {
                                        return (
                                            <SwiperSlide key={url_index}>
                                                    <div className={`background-image image`} style={{backgroundImage: `url('${url}')`}}/>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        )
                    })
                }
            </>
        </div>
    )
}

export default Slider;