import React from "react";
import styles from './styles/describe.module.scss'


const Describe = (props: any) => {
    const screenData = props?.screenData
    return (
        <div className={"container"}>
            <div className={`${styles.describe} `}>
                <div className={`${styles.describeContent} justify-content-center align-items-center`}>
                        {
                            screenData?.description.split(";")?.map((item: string, index: number) => {
                                return (
                                    <span key={`desc_${index}`}>{item}</span>
                                )
                            })
                        }
                </div>
            </div>
        </div>


    )
}
export default Describe