import React from "react";

//scss
import styles from './styles/manufacturing.module.scss'
// image


import ManuFactoringConnect from 'assets/icons/ic-home-manufacture-connect.svg'
import ImgManuFacTuring1 from 'assets/images/home-manufacture-b1.png'
import ImgManuFacTuring2 from 'assets/images/home-manufacture-b2.png'
import ImgManuFacTuring3 from 'assets/images/home-manufacture-b3.png'
import ImgManuFacTuring4 from 'assets/images/home-manufacture-b4.png'
import ImgManuFacTuring5 from 'assets/images/home-manufacture-b5.png'
import ItemMenuFactoring from "components/Manufacturing/components/ItemManufacturing/ItemMenufactoring"
import TitleName from "components/TitleName/TitleName"
import {NavLink} from "react-router-dom"
const ManuFacTuring=()=>{
    return (
        <div className={`${styles.manufacturing}`}>
            <div className={`${styles.manufacturingTitle}`}>
                <div>
                    <TitleName title={"Quy Trình Sản Xuất"}/>
                </div>

            </div>
            <div  className={`${styles.manufacturingContainer}`}>
                <div className={styles.manufacturingItem1}>
                    <ItemMenuFactoring imgVariable={ImgManuFacTuring1}/>
                </div>

                <img alt={"ManuFactoringConnect"} src={ManuFactoringConnect}/>
                <div className={styles.manufacturingItem2}>
                    <ItemMenuFactoring imgVariable={ImgManuFacTuring2}/>
                </div>
                <img alt={"ManuFactoringConnect"} src={ManuFactoringConnect}/>

                <div className={styles.manufacturingItem3}>
                    <ItemMenuFactoring imgVariable={ImgManuFacTuring3}/>
                </div>
                <img alt={'ManuFactoringConnect'} src={ManuFactoringConnect}/>
                <div className={styles.manufacturingItem1}>
                    <ItemMenuFactoring imgVariable={ImgManuFacTuring4}/>
                </div>

                <img alt={'ManuFactoringConnect'} src={ManuFactoringConnect}/>
                <div className={styles.manufacturingItem5}>
                    <ItemMenuFactoring imgVariable={ImgManuFacTuring5}/>
                </div>
                <NavLink to={'/quy-trinh-san-xuat'}>  <i className="fa-solid fa-angle-right"></i></NavLink>
            </div>

            <img alt={'ManuFactoringConnect'} src={ManuFactoringConnect}/>
            <div className={styles.manufacturingItem5}>
                <ItemMenuFactoring imgVariable={ImgManuFacTuring5}/>
            </div>
            <i className="fa-solid fa-angle-right"></i>

        </div>
    )
}
export  default ManuFacTuring