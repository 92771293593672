import instances from "../../../@core/plugin/axios"

export const GET_PAGE_DATA = 'GET_PAGE_SETTING'

export const getScreenData = (screen, website) => {
    return async dispatch => {
        await instances.get(`/setting-landing?screen=${screen}&website=${website}&status=1`).then(response => {
            dispatch({
                type: GET_PAGE_DATA,
                screen: screen,
                response: response?.data?.data
            })
        })
    }
}
export const sendPartnerData = async (formData) => {
    const config = {headers: {'Content-Type': 'application/json'}}
    return await instances.post('/customer/contact', formData, config)
}
