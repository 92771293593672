import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getScreenData} from "../../redux/actions/home-page";
import {PAGE_ENUM} from "../../enum/page-constrant";
import styles from "./styles/product.module.scss"
import Page from "components/Page/Page";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import LineSlogan from "components/LineSlogan/LineSlogan";

const Product = () => {

    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)
    const mapping = [
        {
            id: 'trai-nghiem'
        },
        {
            id: 'truyen-thong'
        },
        {
            id: 'tieu-chuan'
        },
        {
            id: 'phin-nhanh'
        },
        {
            id: 'tien-dung'
        }
    ]
    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.product, PAGE_ENUM.website))
    }, [dispatch])

    const screenData = () => {
        let screenData: any = []
        let position = 1
        screenData = store?.homeScreen?.screen_landings?.filter((item: any) => item?.status === true)
        screenData = screenData?.sort((item1: any, item2: any) => {
            return item1.screen.split("screen_item_")[1] - item2.screen.split("screen_item_")[1]
        })
        screenData?.forEach((item: any) => {
            item.position = position
            position++
        })
        if (screenData?.length > 0) {
            screenData[screenData?.length - 1].isLast = true
        }
        return screenData
    }
    const getImage = (item: any) => {
        let url = ''
        if (item) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }
    const getBackground = (item: any) => {
        let url = ''
        if (item) {
            if (window.innerWidth > 768) {
                url = item.image_url
            }
        }
        return url
    }
    return (store?.homeScreen !== undefined && store?.homeScreen !== null ?
            <>
                <Page isHiddenFooter={false}>
                    <Breadcrumb title={'Sản phẩm'}/>
                    <div className={`container ${styles.paddingContainer}`}>
                        {
                            screenData() && screenData().length > 0 &&
                            screenData().map((item: any, index: number) => {
                                return (
                                    <div className={styles.component} key={index} id={`${mapping[index].id}`} >
                                        <div className={`background-image ${styles.productContainer}`}
                                             style={{backgroundImage: `url('${getBackground(item)}')`}}>
                                            <div className={styles.contentWrapper}>
                                                <div className={`background-image ${styles.titleBackground}`}>
                                                    <span className={styles.title}>{item?.name}</span>
                                                </div>
                                                <span className={styles.description}>{item?.description}</span>
                                                <a className={styles.more} href={`/san-pham/${item.id}`}>Tìm hiểu thêm</a>
                                            </div>
                                            <div className={styles.productImageContainer}>
                                                <div className={`background-image ${styles.productImage}`}
                                                     style={{backgroundImage: `url('${getImage(item)}')`}}/>
                                            </div>
                                        </div>
                                        {
                                            !item.isLast &&
                                            <LineSlogan/>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Page>
            </> : <></>
    )
}
export default Product