import {useEffect, useState} from "react"
import styles from "./styles/factory-component.module.scss"
import publicStyles from "../../styles/showroom.module.scss"

const FactoryComponent = (props: any) => {
    const [screenData, setScreenData] = useState(props?.screenData)

    useEffect(() => {
        setScreenData(props?.screenData)
    }, [props?.screenData])

    return (
        <div className={styles.componentContainer}>
            <div className={publicStyles.titleContainer}>
                <span className={publicStyles.mainTitle}>{screenData?.name}</span>
            </div>
            <div className={publicStyles.container}>
                <span className={styles.description}>{screenData?.description}</span>
            </div>
            {
                screenData && screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                screenData.screen_item_details?.map((screen_item_detail: any, detail_index: number) => {
                    return (
                        <div key={`detail-${detail_index}`} className={styles.imagesContainer}>
                            {
                                screen_item_detail && screen_item_detail.image_urls && screen_item_detail.image_urls?.length > 0 &&
                                screen_item_detail.image_urls?.map((url: string, url_index: number) => {
                                    return (
                                        <div className={styles.imageContainer} key={`url-${url_index}`}>
                                        <div className={`background-image ${styles.image}`}
                                             style={{backgroundImage: `url('${url}')`}} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
export default FactoryComponent