import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ValueCoffee from "./components/ValueCoffee/ValueCoffee"
import Page from "components/Page/Page"
import {PAGE_ENUM} from "../../enum/page-constrant"
import {getScreenData} from "../../redux/actions/home-page"
import LineSlogan from "components/LineSlogan/LineSlogan"
import PlantingArea from "./components/PlantingArea/PlantingArea"
import IntroduceSlider from "./components/IntroduceSlider/IntroduceSlider"
import BannerHome from "./components/BannerHome/BannerHome"
import ManufactureDesktop from "pages/Home/components/Manufacture/components/ManufactureDesktop/ManufactureDesktop";
import ManufacturePhone from "pages/Home/components/Manufacture/components/ManufacturePhone/ManufacturePhone";
import Factory from "pages/Home/components/Factory/Factory";

function Home() {
    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)

    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.home, PAGE_ENUM.website))
    }, [dispatch])

    const screenHomeNumber = (screenNumber: number) => {
        return store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screenNumber}`)
    }

    return (store?.homeScreen !== undefined && store?.homeScreen !== null ?
            <>
                <Page isHiddenFooter={false}>
                    <BannerHome bannerHome={screenHomeNumber(1)}/>
                    <IntroduceSlider screenData={screenHomeNumber(2)}/>
                    <LineSlogan/>
                    <PlantingArea plantingArea={(screenHomeNumber(3))}/>
                    <LineSlogan/>
                    <ValueCoffee valueCoffee={(screenHomeNumber(4))}/>

                    <Factory factory={(screenHomeNumber(5))}/>
                    <LineSlogan/>
                    {
                        window.innerWidth > 768 &&
                        <ManufactureDesktop screenData={(screenHomeNumber(6))}/>
                    }
                    {
                        window.innerWidth <= 768 &&
                        <ManufacturePhone screenData={(screenHomeNumber(6))}/>
                    }
                </Page>
            </>
            :
            <></>
    )
}

export default Home