import {
    GET_PAGE_DATA
} from '../../actions/home-page'

const initialState = {
    header: {}
}

const HeaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAGE_DATA:
            return {
                ...state,
                header: action.response
            }
        default:
            return { ...state }
    }
}

export default HeaderReducer
