import React from "react";
import styles from './styles/image.module.scss'

const Image=(props: any)=>{
    const screenData = props?.screenData
    const getImage = (item: any) => {
        let url = ''
        if (screenData) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    return(
        <div className={`container ${styles.imageContainer}`} >
            <div className={`${styles.image} background-image container`}  style={{backgroundImage:`url('${getImage(screenData)}')`}}/>
        </div>

    )
}

export default Image;