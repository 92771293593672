import React from 'react'


import styles from './styles/zili-farm.module.scss'

const ZiLiFarm = (props: any) => {
    const itemData = props?.itemData
    return (
        <div className={` ${styles.farm}`}>
            <div className={`${styles.farmAddress}`}>
                {
                    itemData && itemData.split(";")?.length > 0 &&
                    itemData.split(";")?.map((item: string, index: number) => {
                        return (
                            <div className={styles.farmAddressItem} key={`item_${index}`}>
                                <span className={styles.farmAddressItemTitle}>{item.split("%")[0]}</span>
                                <span className={styles.farmAddressItemContent}>{item.split("%")[1]}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default ZiLiFarm