import React from "react";

import styles from './styles/partner-title.module.scss'
const PartnerTitle=()=>{
    return(
        <div className={`${styles.partnerTitle} align-items-center justify-content-center container`}>
            <h2>CHÍNH SÁCH CHO KHÁCH HÀNG SỈ</h2>
        </div>
    )
}
export default PartnerTitle