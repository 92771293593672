import {useEffect, useState} from "react";
import styles from "./styles/showroom-banner-component.module.scss"

const ShowroomBannerComponent = (props: any) => {
    const [screenData, setScreenData] = useState(props?.screenData)

    useEffect(() => {
        setScreenData(props?.screenData)
    }, [props?.screenData])

    const getImageURL = (item: any) => {
        let url = ''
        if (item) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    return (
        <>
            <div className={styles.componentContainer}>
                <div className={styles.contentContainer}>
                    <span className={styles.title}>{screenData?.name}</span>
                    <span className={styles.description}>{screenData?.description}</span>
                </div>
                {
                    <div className={`background-image ${styles.bannerImage}`}
                         style={{backgroundImage: `url('${getImageURL(screenData)}')`}}>
                        <div className={styles.bannerImageEffect}/>
                    </div>
                }
            </div>
        </>
    )
}
export default ShowroomBannerComponent