import React from "react"
import styles from './styles/tab-shop.module.scss'
import icArrow from 'assets/icons/ic-arrow-partner.svg'

const TabShop=(props: any)=>{
    const screenItem = props?.screenData
    return(
        <div className={styles.tabShop}>
            {
                screenItem && screenItem.screen_item_details && screenItem.screen_item_details?.length > 0 &&
                screenItem.screen_item_details?.map((item_detail: any, detail_index: number) => {
                    return (
                        <div className={styles.tabShopDiscount} key={`detail_${detail_index}`}>
                            {
                                item_detail.type === "customer" &&
                                item_detail.titles && item_detail.titles?.length > 0 &&
                                item_detail.titles?.map((title: string, index: number) =>(
                                        <div className={styles.wrapper} key={`title_${index}`}>
                                            <span className={styles.title}>{title}</span>
                                            <div className={styles.contentWrapper}>
                                                {
                                                    item_detail &&
                                                    item_detail.contents && item_detail.contents?.length > 0 &&
                                                    item_detail.contents[index]?.split(";")?.map((content: string, content_index: number) => {
                                                        return (
                                                            <span className={styles.content}
                                                                  dangerouslySetInnerHTML={{
                                                                      __html: content.replace(/\*\*(.*?)\*\*/g, "<p>&nbsp;$1&nbsp;</p>")
                                                                          .replace(/:arrow:/g,`<img src=${icArrow} alt='image' />`)
                                                                  }}
                                                                  key={`content_${content_index}`}/>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                    )
                                )
                            }
                            {
                                item_detail.type === "customer" &&
                                <div className={styles.decorImageContainer}>
                                    <div className={`background-image ${styles.decorImage}`}
                                         style={{backgroundImage: `url('${item_detail.image_url}')`}}/>
                                </div>
                            }
                        </div>

                    )
                })
            }
        </div>
    )
}
export default  TabShop