
import styles from "./styles/address-component.module.scss"

import TitleName from "components/TitleName/TitleName"
import React, {useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import SwiperCore  from "swiper";
const AddressComponent = () => {

    const [isActive,setIsActive]=useState(true);
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const [swiperRef, setSwiperRef] = useState<SwiperCore>()

    const slideTo = (index: number) => {
        swiperRef?.slideTo(index, 0);
    };
    return (
        <div className={`${styles.componentContainer} container`}>
            <div className={`${styles.titleContainer} justify-content-center align-item-center`}>
                <div>
                    <TitleName title={"THÔNG TIN ĐỊA CHỈ"}/>
                </div>
            </div>
            <div className={styles.addressContainerMain}>
                <div className={styles.addressContainerMainLeft}>
                    <div  ref={navigationPrevRef}
                          className={ isActive?`${styles.Active} ${styles.addressContainerMainLeftAction}`:  styles.addressContainerMainLeftAction  }
                          onClick={()=> {
                              if (!isActive) {
                                  slideTo(1)
                                  setIsActive(true)
                              }
                          }}>
                        <h2 className={styles.title}>NHÀ XƯỞNG LÂM ĐỒNG</h2>
                        <p className={styles.address}>Số 206-207, Quốc Lộ 20, Thôn 8, Xã Liên Đầm, Huyện Di Linh, Tỉnh Lâm Đồng</p>
                        <div  className={styles.groupBottom}>
                            <span className={styles.groupBottomOpen}>Mở cửa: <a href={"#3"}>07:30 - 17:00 | Thứ 2 - Chủ nhật</a></span>
                            <span className={styles.groupBottomView}><a href={"#4"}> Xem hình ảnh</a></span>
                        </div>
                    </div>
                    <div ref={navigationNextRef}
                         className={!isActive?`${styles.Active} ${styles.addressContainerMainLeftAction}`:  styles.addressContainerMainLeftAction} onClick={()=> {
                            if (isActive) {
                                slideTo(0)
                                setIsActive(false)
                            }
                    }}>
                        <h2 className={styles.title}>SHOWROOM HỒ CHÍ MINH</h2>
                        <p className={styles.address}>42 Đường Số 2 Cityland Center Hills, Phường 7, Quận Gò Vấp, TP Hồ Chí Minh</p>
                        <div className={styles.groupBottom}>
                            <span className={styles.groupBottomOpen}>Mở cửa:<a href={"#1"}> 07:30 - 17:00 | Thứ 2 - Chủ nhật</a> </span>
                            <span className={styles.groupBottomView}><a href={"#2"}> Xem hình ảnh</a></span>
                        </div>

                    </div>

                </div>
                <div className={styles.addressContainerMainRight}>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        onSwiper={setSwiperRef}
                        className="mySwiperStore"
                    >
                        <SwiperSlide>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6776539849684!2d108.01481431533713!3d11.574950247110007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3173ff41deca6eb5%3A0x30c8badbcc0a1507!2sZili%20Coffee!5e0!3m2!1svi!2s!4v1653463318986!5m2!1svi!2s"
                                className={styles.map} loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade" key={"address2"}  title={"address2"}/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d823.8220238806371!2d106.68147614433373!3d10.82813735480347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529ce863d025f%3A0xec5370726afc803f!2sZili%20Coffee!5e0!3m2!1svi!2s!4v1651131196550!5m2!1svi!2s"
                                className={styles.map} loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade" key={"address1"}  title={"address1"}/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </div>
    )
}
export default AddressComponent