import styles from "./styles/manufacture-desktop.module.scss"
import arrow from "assets/images/arrow.png"
import longArrow from "assets/images/long-arrow.png"
import TitleName from "components/TitleName/TitleName";
const ManufactureDesktop = (props: any) => {
    const screenData = props?.screenData

    const getName = (item: any) => {
        let name = ""
        if (item) {
            if (window.innerWidth <= 768) {
                name = item.mobile_name
            } else {
                name = item.desktop_name
            }
        }
        return name
    }
    const getImage = (item: any) => {
        let url = ""
        if (item) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }
    return (
        <div className={styles.componentContainer}>
            <div className={styles.componentBackground}>
                <div className={`d-flex justify-content-center`}>
                    <div className={styles.titleContainer}>
                        <TitleName title={screenData?.name}/>
                    </div>
                </div>

                <div className={`d-flex justify-content-center ${styles.contentContainer}`}>
                    <div className={styles.gridContainer}>
                        {
                            screenData && screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                            screenData.screen_item_details?.sort((itemA: any, itemB: any) => {return itemA?.position - itemB?.position}).map((screen_item_detail: any, index: number) => {
                                return (
                                    <div className={`${styles.grid} ${styles[`position${index + 1}`]}`} key={index}>
                                        <div className={styles.gridContent} onClick={() => {
                                            props?.scrollDown()
                                            props?.slideTo(index)
                                        }}>
                                            <div className={`background-image ${styles.contentImage}`} style={{backgroundImage: `url('${getImage(screen_item_detail)}')`}} />
                                            <span>{index + 1}. {getName(screen_item_detail)}</span>
                                        </div>
                                        <div className={styles.arrowContainer}>
                                            <div className={`background-image ${styles.arrow}`} style={{backgroundImage: `url('${arrow}')`}}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={`background-image ${styles.longArrow}`} style={{backgroundImage: `url('${longArrow}')`}}/>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ManufactureDesktop