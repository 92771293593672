import Page from "components/Page/Page"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getScreenData} from "../../redux/actions/home-page";
import {PAGE_ENUM} from "../../enum/page-constrant";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ContentSlider from "pages/Production/components/ContentSlider/ContentSlider";
import SwiperCore from "swiper";
import ManufacturePhone from "pages/Production/components/Manufacture/components/ManufacturePhone/ManufacturePhone";
import ManufactureDesktop from "pages/Production/components/Manufacture/components/ManufactureDesktop/ManufactureDesktop";


function Production() {
    const dispatch = useDispatch()

    const store = useSelector((state: any) => state.HomePageReducer)
    const [swiperRef, setSwiperRef] = useState<SwiperCore>()
    const [yPos, setYPos] = useState()

    const slideTo = (index: number) => {
        swiperRef?.slideTo(index, 0);
    };
    const scrollDown = () => {
        window.scrollTo({
            top: yPos,
            behavior: "smooth"
        })
    }
    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.production, PAGE_ENUM.website))
    }, [dispatch])
    const getData = (screenNumber: number) => {
        let screenData = []
        screenData = store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screenNumber}`)
        if (screenData?.length > 0) {
            screenData.sort((itemA: any, itemB: any) => {return itemA?.position - itemB?.position})
        }
        return screenData
    }
    const getYPos = (position: any) => {
        setYPos(position)
    }

    return (store?.homeScreen !== undefined && store?.homeScreen !== null ?
        <Page>
            <Breadcrumb title={'Sản xuất'}/>
            {
                window.innerWidth <= 768 &&
                <ManufacturePhone screenData={getData(1)} slideTo={slideTo} scrollDown={scrollDown} />
            }
            {
                window.innerWidth > 768 &&
                <ManufactureDesktop screenData={getData(1)} slideTo={slideTo} scrollDown={scrollDown} />
            }
            <ContentSlider screenData={getData(1)} setSwiperRef={setSwiperRef} setYPos={(position: any) => getYPos(position)}  />
        </Page> : <></>
    )
}

export default Production