import React, {useState} from "react"

import styles from './styles/partner-form.module.scss'
import Modal from "pages/Partner/components/modal/Modal"
import { useForm, SubmitHandler } from "react-hook-form"
import {sendPartnerData} from "../../../../redux/actions/home-page"

interface IForm{
    name:string,
    description:string,
    email:string,
    phone:string
}
const PartnerForm=()=>{

    const { register, handleSubmit, formState: { errors } } = useForm<IForm>()
    const [partnerType, setPartnerType] = useState(1)
    const [isOpen,setIsOpen]=useState(false)
    const optionValidator={
        name:{
            minLength:6,
            required:true,
            err:{
                minLength:"Chiều dài tối thiểu 6 kí tự",
                required:"Trường name không được để trống"
            }
        },
        phone:{
            required:true,
            minLength: 10,
            maxLength:16,
            pattern:/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
            err:{
                minLength:"Chiều dài tối thiểu 10 kí tự",
                maxLength:"Chiều dài tối đa 16 kí tự",
                required:"Trường name không được để trống",
                pattern:"Vui lòng nhập đúng định dạng số điện thoại"
            }
        },
        email:{
            required:false,
            pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            minLength:6,
            err:{
                minLength:"Chiều dài tối thiểu 6 kí tự",
                required:"Trường name không được để trống",
                pattern:"Sai định danh email"
            }
        },
        description:{
            required:false,
            minLength:10,
            maxLength: 200,
            err:{
                minLength:"Chiều dài tối thiểu 10 kí tự",
                required:"Trường name không được để trống",
                maxLength:"Chiều dài tối đa là 200 kí tự"
            }

        }
    }

    //function
    const onSubmit: SubmitHandler<IForm> = async (data: any) => {
        data.type = partnerType
        const response = await sendPartnerData(data)
        setIsOpen(true);
        if (response.data.status) {
            //check if phone number used
        }
    }

    function handleCloseModal(){
        setIsOpen(false)
    }

    return(
        <>
            <div className={styles.partnerForm}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.partnerFormHead}>
                        <h2 className={styles.partnerFormHeadTitle}>THÔNG TIN LIÊN HỆ</h2>
                        <p className={styles.partnerFormHeadNote}>Khách hàng có thể để thông tin dưới đây, nhân viên tư vấn sẽ liên hệ và hỗ trợ ngay!  </p>
                    </div>
                    <div className={styles.partnerFormBody}>
                            <div  className={`${styles.partnerFormBody1} ${styles.body}`}>
                                <input className={styles.partnerFormBody1Input}
                                    type={"text"}
                                    placeholder={"Họ và tên *"}
                                    // onChange={(e)=>setName(e.target.value)}
                                    {...register("name", {
                                        required: optionValidator.name.required
                                        ,minLength:optionValidator.name.minLength})
                                    }
                                />
                                <p className={styles.partnerFormBody1Error} >
                                    {errors?.name?.type==='required'&&optionValidator.name.err.required}
                                    {errors?.name?.type==='minLength'&&optionValidator.name.err.minLength}
                                </p>
                            </div>
                            <div className={`${styles.partnerFormBody2} ${styles.body}`}>
                                <div className={styles.partnerFormBody2Container}>
                                    <input className={styles.partnerFormBody2ContainerInput}
                                        type={"text"}
                                        placeholder={"Email *"}
                                        // onChange={(e)=>setEmail(e.target.value)}
                                        {...register("email",{
                                                required: optionValidator.email.required ,
                                                minLength:optionValidator.email.minLength,
                                                pattern:optionValidator.email.pattern
                                             })
                                        }
                                    />
                                    <p  className={styles.partnerFormBody2ContainerError}>
                                        {errors?.email?.type==='minLength'&&optionValidator.email.err.minLength}
                                        {errors?.email?.type==='required'&&optionValidator.email.err.required}
                                        {errors?.email?.type==='pattern'&&optionValidator.email.err.pattern}
                                    </p>
                                </div>
                                <div  className={styles.partnerFormBody2Container} >
                                    <input type={"text"} className={styles.partnerFormBody2ContainerInput}
                                           placeholder={"Số điện thoại *"}
                                           // onChange={(e)=>setPhone(e.target.value)}
                                           required={true}
                                           {...register("phone",{
                                               required:optionValidator.phone.required,
                                               minLength:optionValidator.phone.minLength,
                                               maxLength:optionValidator.phone.maxLength,
                                               pattern:optionValidator.phone.pattern
                                             })
                                           }
                                    />
                                    <p className={styles.partnerFormBody2ContainerError}>
                                        {errors?.phone?.type==='required'&&optionValidator.phone.err.required}
                                        {errors?.phone?.type==='minLength'&&optionValidator.phone.err.minLength}
                                        {errors?.phone?.type==='maxLength'&&optionValidator.phone.err.maxLength}
                                        {errors?.phone?.type==='pattern'&&optionValidator.phone.err.pattern}
                                    </p>
                                </div>
                            </div>
                            <div className={`${styles.partnerFormBody3} ${styles.body}`}>
                                <textarea className={styles.partnerFormBody3Text}
                                    placeholder={"Nội dung..."}
                                    // onChange={(e)=>setContent(e.target.value)}
                                    {...register("description",{
                                        minLength:optionValidator.description.minLength,
                                        required:optionValidator.description.required,
                                        maxLength:optionValidator.description.maxLength
                                    })}
                                >
                                </textarea>
                                <p  className={styles.partnerFormBody3Error}>
                                    {errors?.description?.type==='minLength'&&optionValidator.description.err.minLength}
                                    {errors?.description?.type==='required'&&optionValidator.description.err.required}
                                    {errors?.description?.type==='maxLength'&&optionValidator.description.err.maxLength}
                                </p>
                            </div>
                            <div className={`${styles.partnerFormBodyRadio} ${styles.body}`}>
                                <div className={styles.partnerFormBodyRadioGroup} >
                                    <label className={styles.partnerFormBodyRadioGroupLabel} >
                                        <input className={styles.partnerFormBodyRadioGroupLabelInput} type="radio" name="radio-button" value="agency" onClick={() => setPartnerType(1)} defaultChecked={true} />
                                        <span className={styles.partnerFormBodyRadioGroupLabelName}>Đại lý - cửa hàng</span>
                                    </label>
                                    <label className={`${styles.partnerFormBodyRadioGroup2} ${styles.partnerFormBodyRadioGroupLabel}`}>
                                        <input className={styles.partnerFormBodyRadioGroupLabelInput} type="radio" name="radio-button" value="customer" onClick={() => setPartnerType(2)}/>
                                        <span className={styles.partnerFormBodyRadioGroupLabelName}>Quán cà phê</span>
                                    </label>
                                </div>
                            </div>
                    </div>
                    <div className={styles.partnerFormBottom}>
                        <div className={styles.partnerFormBottomButton}>
                            <button className={styles.partnerFormBottomButtonSubmit} type={"submit"}>Gửi </button>
                        </div>
                        <div className={styles.partnerFormBottomContact}>
                            <p className={styles.partnerFormBottomContactContent}>Hoặc khách hàng có thể trực tiếp gọi qua số điện thoại:<span onClick={() =>  window.open('tel:0702826868')}> 0702826868</span></p>
                        </div>
                    </div>
                    {isOpen?<Modal handleCloseModal={handleCloseModal} />:<></>}
                </form>
            </div>

        </>
    )
}
export default PartnerForm