import React, {useState} from "react"

import styles from './styles/part-information.module.scss'
import TabWholesale from "pages/Partner/components/partner-information/components/TabWholesale/TabWholesale"
import TabShop from "pages/Partner/components/partner-information/components/TabShop/TabShop"

const PartnerInformation = (props: any) => {
    const [activeTab,setActiveTab]=useState(true)

    return (
        <>
            <div className={styles.partnerInfo}>
                <div className={styles.partnerInfoHead}>
                    <div className={styles.partnerInfoHeadItem}>
                        <span onClick={()=>setActiveTab(true)} className={activeTab ? `${styles.active}`:""} >KHÁCH HÀNG ĐẠI LÝ</span>
                    </div>
                    <div className={styles.partnerInfoHeadItem}>
                        <span onClick={()=>setActiveTab(false)} className={!activeTab ? `${styles.active} ${styles.marginTab}`:`${styles.marginTab}`}>KHÁCH HÀNG QUÁN</span>
                    </div>


                </div>
                <div className={styles.partnerInfoContent}>
                    {
                        activeTab ?<TabWholesale screenData={props?.screenData}/>:<TabShop screenData={props?.screenData}/>
                    }
                </div>

            </div>
        </>
    )
}
export default PartnerInformation