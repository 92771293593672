import React from 'react'
import styles from './styles/planting-info.module.scss'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import {Navigation, Pagination} from 'swiper'
//icon
import icNext from '../../../../../../assets/icons/ic-next-slider.svg'
import ZiLiFarm from "components/ZiliFarm/ZiliFarm";

const PlantingInfo = (props: any) => {
    const navigationNextRef = React.useRef(null)
    const navigationPrevRef = React.useRef(null)
    const pagination = {
        clickable: true,
        renderBullet: (index: number, className: string) => {
            return '<div class=" '
                + className + '' +
                ' ' +
                `paginationPlanting`
                + '"' +
                '>' +
                ' <span class="' +
                'radio-color' +
                '"'
                + '>' +
                '</span>' +
                ' <span class="' +
                'name' +
                '"'
                + '>' +
                `${props?.plantingArea?.screen_item_details[index]?.title_one}` +
                '</span>' +
                '</div>'
        }
    }
    return (
        <>
            <div className={styles.containerSlider}>
                <div className={`background-image ${styles.sliderNext}`} style={{backgroundImage: `url('${icNext}')`}}
                     ref={navigationNextRef}/>
                <Swiper
                    direction={'horizontal'}
                    slidesPerView={1}
                    navigation={{
                        prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current
                    }}
                    pagination={pagination}
                    modules={[Navigation, Pagination]}
                    className={'sliderPlanting'}
                    onInit={(swiper) => {
                        const a: any = swiper.params.navigation
                        a.prevEl = navigationPrevRef.current
                        a.nextEl = navigationNextRef.current
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                    }}
                >
                    {
                        props?.plantingArea &&
                        props?.plantingArea.screen_item_details && props?.plantingArea.screen_item_details?.length > 0 &&
                        props?.plantingArea.screen_item_details?.map((item_detail: any, item_detail_index: number) =>
                            (
                                <SwiperSlide key={`detail${item_detail_index}`}>
                                    <div className={styles.containerContent}>
                                        <div className={styles.contentReview}>
                                            <div className={styles.contentReviewActionMap}>
                                                <div className={styles.contentReviewMap}>
                                                    <div className={`background-image ${styles.img}`}
                                                         style={{backgroundImage: `url('${item_detail?.image_urls?.length > 0 ? item_detail?.image_urls[0] : ''}')`}}/>
                                                </div>
                                            </div>
                                            <p className={styles.contentReviewDescription}>
                                                {item_detail?.description}
                                            </p>
                                            <div>
                                                <ZiLiFarm itemData={item_detail.desktop_description}/>
                                            </div>
                                            <div className={styles.btnContainer}>
                                                <button className={`on-desktop ${styles.btnLoadMore}`}
                                                        onClick={() => window.location.href = '/vung-trong'}>Xem thêm
                                                </button>
                                            </div>
                                        </div>
                                        <button className={`on-phone ${styles.btnLoadMore}`}
                                                onClick={() => window.location.href = '/vung-trong'}>Xem thêm
                                        </button>
                                    </div>

                                </SwiperSlide>
                            )
                        )

                    }
                </Swiper>
            </div>
        </>

    )
}
export default PlantingInfo