import React from 'react'
import style from './styles/footer.module.scss'
//icon
import logoImgDesktop from 'assets/images/zili-logo-desktop.webp'
import logoImgPhone from 'assets/images/zili-logo-phone.webp'
import iconPhone from 'assets/icons/ic-footer-phone.svg'
import iconMail from 'assets/icons/ic-footer-mail.svg'
import iconAddress from 'assets/icons/ic-footer-address.svg'
import icFacebook from '../../../../assets/icons/ic-facebook.svg'
import icInstagram from '../../../../assets/icons/ic-instagram.svg'
import icTelegram from '../../../../assets/icons/ic-telegram.svg'
import govLogo from '../../../../assets/images/gov-logo.svg'

function Footer() {

    return (
        <>

            <footer className={style.footerContainer}>
                <div className={style.col1}>
                    <div className={style.wrapper}>
                        <div className={style.logoContainer}>
                            <img src={window.innerWidth > 768 ? logoImgDesktop : logoImgPhone} alt={'logo zili'}/>
                        </div>
                        <div className={style.contentContainer}>
                            <span className={style.content}>Zili Coffee là hành trình tìm kiếm hương vị quyến rũ của hạt cà phê được tạo nên từ những nguồn nguyên liệu tốt nhất, kết hợp với kỹ thuật hiện đại, cùng với sự hòa hợp của tình yêu thương và niềm đam mê</span>
                        </div>
                        <div className={style.copyrightContainerDesktop}>
                            <span className={style.copyright}>&copy; 2022 Công Ty TNHH Cà Phê Rang Xay Phu Đoan</span>
                        </div>
                    </div>
                </div>
                <div className={style.col2}>
                    <div className={style.wrapper}>
                        <span className={style.title}>DANH MỤC</span>
                        <a href={'/'}>Trang chủ</a>
                        <a href={'/san-xuat'}>Sản xuất</a>
                        <a href={'/san-pham'}>Sản phẩm</a>
                        <a href={'/doi-tac'}>Đối tác</a>
                        <a href={'/cua-hang'}>Cửa hàng</a>
                    </div>
                </div>
                <div className={style.col3}>
                    <div className={style.wrapper}>
                        <span className={style.title}>LIÊN HỆ</span>
                        <div className={style.contactItemContainer}>
                            <div className={style.iconContainer}>
                                <div className={`background-image ${style.img}`} style={{backgroundImage: `url('${iconAddress}')`}}/>
                            </div>
                            <div className={style.contentContainer}>
                                <span className={style.content}>Số 206-207, Quốc Lộ 20, Thôn 8, Xã Liên Đầm, Huyện Di Linh, Tỉnh Lâm Đồng</span>
                            </div>
                        </div>
                        <div className={style.contactItemContainer}>
                            <div className={style.iconContainer}>
                                <div className={`background-image ${style.img}`} style={{backgroundImage: `url('${iconAddress}')`}}/>
                            </div>
                            <div className={style.contentContainer}>
                                <span className={style.content}>42 Đường số 2, Khu Cityland, P.7,<br/>Quận Gò Vấp, TP.Hồ Chí Minh</span>
                            </div>
                        </div>
                        <div className={style.contactItemContainer}>
                            <div className={style.iconContainer}>
                                <div className={`background-image ${style.img}`} style={{backgroundImage: `url('${iconMail}')`}}/>
                            </div>
                            <div className={style.contentContainer}>
                                <span className={style.contentPhoneAndEmail} onClick={() => window.open('mailto:info.zilicoffee@gmail.com')}>Email: zilicoffee@gmail.com</span>
                            </div>
                        </div>
                        <div className={style.contactItemContainer}>
                            <div className={style.iconContainer}>
                                <div className={`background-image ${style.img}`} style={{backgroundImage: `url('${iconPhone}')`}}/>
                            </div>
                            <div className={style.contentContainer}>
                                <span className={style.contentPhoneAndEmail} onClick={() => window.open('tel:0702826868')}>Điện Thoại: 0702826868</span>
                            </div>
                        </div>
                        <div className={`background-image ${style.govLogo}`}
                             style={{backgroundImage: `url('${govLogo}')`}}
                             onClick={() => window.open('http://online.gov.vn/Home/WebDetails/94978')} />
                    </div>
                </div>
                <div className={style.col4}>
                    <div className={style.wrapper}>
                        <span className={style.title}>THEO DÕI ZILI COFFEE</span>
                        <div className={style.socialContainer}>
                            <div className="fb-page" data-href="https://www.facebook.com/zilicoffee" data-tabs=""
                                 data-width="" data-height="" data-small-header="false"
                                 data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false">
                                <blockquote cite="https://www.facebook.com/zilicoffee" className="fb-xfbml-parse-ignore">
                                    <a href="https://www.facebook.com/zilicoffee">Meta</a></blockquote>
                            </div>
                        </div>
                        <div className={style.containerFooterIcons}>
                            <div className={style.iconContainer}>
                                <a href={'https://www.facebook.com/zilicoffee'}>
                                <div className={`background-image ${style.icon}`}
                                     style={{backgroundImage: `url('${icFacebook}')`}}/></a>
                            </div>
                            <div className={style.iconContainer}>
                                <a href={'https://www.twitter.com/zilicoffee'}>
                                <div className={`background-image ${style.icon}`}
                                     style={{backgroundImage: `url('${icTelegram}')`}}/></a>
                            </div>
                            <div className={style.iconContainer}>
                                <a href={'https://www.instagram.com/zilicoffee'}>
                                <div className={`background-image ${style.icon}`}
                                     style={{backgroundImage: `url('${icInstagram}')`}}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.copyrightContainerPhone}>
                    <span className={style.copyright}>&copy; 2022 Công Ty TNHH Cà Phê Rang Xay Phu Đoan</span>
                </div>
            </footer>
        </>
    )
}

export default Footer