import React from "react";
import Page from "components/Page/Page";
import Certification from "./components/Certification/Certification";
import Manufacturing from "components/Manufacturing/Manufacturing";

const AboutZilli =()=>{
    return (
        <>
            <Page>
                <Manufacturing/>
                <Certification/>
            </Page>
        </>
    );
}
export  default AboutZilli;