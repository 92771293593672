import React from 'react'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

function Page(props: any) {
    return(
        <>
            {
                !props?.isHiddenHeader &&
                <Header/>
            }
            {props?.children}
            {
                !props?.isHiddenFooter &&
                <Footer/>
            }
        </>
    )
}

export default Page