import styles from "./styles/land-ext-component.module.scss";
import publicStyle from "../../styles/land-extension.module.scss";

const LandExtComponent = (props: any) => {
    const screenData = props?.screenData

    const getImage = (item: any) => {
        let url = ''
        if (screenData) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    return (
        <div className={`container ${styles.componentContainer}`}>
            <div className={publicStyle.titleContainer}>
                <span className={publicStyle.mainTitle}>{screenData?.name}</span>
            </div>
            <div className={publicStyle.container}>
                <div className={styles.imageContainer}>
                    <div className={`background-image ${styles.leftImage}`}
                         style={{backgroundImage: `url('${getImage(screenData)}')`}}/>
                </div>
                <div className={styles.wrapper}>
                    <div className={`background-image ${styles.contentImage}`}
                         style={{backgroundImage: `url('${getImage(screenData?.screen_item_details[0])}')`}}/>
                    {
                        screenData && screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                        screenData?.screen_item_details[0]?.description?.split(";").map((item: string, index: number) => {
                            return (
                                <span className={styles.description} key={index}
                                     dangerouslySetInnerHTML={{__html: item}}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export default LandExtComponent