export const  PAGE_ENUM = {
    website: 'zili',
    home: 'home',
    product: 'product',
    green_bean: 'green_bean',
    production: 'production',
    partner: 'partner',
    introduce: 'introduce',
    introduce_extension: 'introduce_extension',
    land_extension: 'land_extension',
    about_us: 'about_us',
    showroom: 'showroom',
    store: 'store',
    screen_item: 'screen_item_',
    setting_default: 'setting_default',
    setting_mobile: 'setting_mobile',
    setting_desktop: 'setting_desktop',
    desktop: 'desktop',
    mobile: 'mobile'
}

