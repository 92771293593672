import React from "react";
import styles from './styles/content-phone.module.scss'

import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import ContentItemsPhone
    from "pages/CoffeeBean/components/Content/ContentPhone/components/ContentItemsPhone/ContentItemsPhone";

const ContentPhone = (props: any) => {
    const screenData = props?.screenData

    const getArrDB=(type:string)=>{
        const arr:any[]=  props?.screenData?.screen_item_details||[];
        return  arr.length>0?arr.filter((item:any)=>item.type===type):[]
    }

    return (
        <div className={`${styles.content}`}>
            <div className={`${styles.contentTitle} justify-content-center align-items-center`}>
                <h2>CÀ PHÊ NHÂN</h2>
            </div>
            <div className={styles.contentContent}>
                <Swiper
                    slidesPerView={2.5}
                    spaceBetween={40}
                    modules={[Navigation]}
                    breakpoints={{
                        320: {
                            slidesPerView: 2.5,
                        },
                    }}
                    className={`coffeeBeanSlider ${styles.contentContentRobusta}`}>
                    {
                        screenData && screenData.screen_item_details?.length > 0 &&
                        getArrDB("robusta")?.map((screen_item_detail: any, index: number) => {
                            return (
                                <>
                                    {
                                        screen_item_detail.type === "robusta" &&
                                        <SwiperSlide className={`${styles.contentContentRobustaItem}`}
                                                     key={`robusta_${index}`}>
                                            {/*<ContentItemsPhone screenData={getArrDB('robusta')}/>*/}
                                            <ContentItemsPhone screenData={screen_item_detail} handleToggleModal={props?.handleToggleModal()}/>
                                        </SwiperSlide>
                                    }
                                </>

                            )
                        })
                    }
                </Swiper>
                <Swiper
                    slidesPerView={2.5}
                    spaceBetween={40}
                    modules={[Navigation]}
                    breakpoints={{
                        320: {
                            slidesPerView: 2.5,
                        },
                    }}
                    className={`coffeeBeanSlider ${styles.contentContentArabica}`}>
                    {
                        screenData && screenData.screen_item_details?.length > 0 &&
                        getArrDB("arabica")?.map((screen_item_detail: any, index: number) => {
                            return (
                                <>
                                    {
                                        screen_item_detail.type === "arabica" &&
                                        <SwiperSlide className={`${styles.contentContentArabicaItem}`}
                                                     key={`arabica_${index}`}>
                                            <ContentItemsPhone screenData={screen_item_detail} handleToggleModal={props?.handleToggleModal()}/>
                                        </SwiperSlide>
                                    }
                                </>

                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}
export default ContentPhone;