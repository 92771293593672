import React  from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import IntroduceExtension from "pages/Extension/IntroduceExtension/IntroduceExtension"
import AboutZilli from "pages/AboutZili/AboutZilli"
import Store from "pages/Store/Store"
import Showroom from "pages/Showroom/Showroom"
import LandExtension from "pages/Extension/LandExtension/LandExtension"
import Production from "pages/Production/Production"
import ProductInfo from "pages/Product/components/ProductInfo/ProductInfo";
import Product from "pages/Product/Product"
import Partner from "pages/Partner/Partner"
import CoffeeBean from "pages/CoffeeBean/CoffeeBean";
function App() {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gioi-thieu" element={<IntroduceExtension />} />
            <Route path="/vung-trong" element={<LandExtension />} />
            <Route path="/showroom" element={<Showroom />} />
            <Route path="/ve-zilli" element={<AboutZilli />} />
            <Route path="/doi-tac" element={<Partner />} />
            <Route path="/cua-hang" element={<Store />} />
            <Route path="/san-xuat" element={<Production/>}/>
            <Route path="/san-pham" element={<Product/>}/>
            <Route path="/ca-phe-nhan" element={<CoffeeBean/>}/>
            <Route path="/san-pham/:id" element={<ProductInfo/>}/>
        </Routes>
      </Router>
  )
}

export default App
