import styles from "./styles/content-slider.module.scss"
import React, {useEffect, useRef, useState} from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import {Navigation, Pagination, Thumbs} from "swiper"
//import icon
import icPlayVideo from 'assets/icons/ic-play-video.webp'
import IconNext from 'assets/icons/ic-next-manufacturing.svg'
import IconPrev from 'assets/icons/ic-prev-manufacturing.svg'
import TitleName from "components/TitleName/TitleName";


const ContentSlider = (props: any) => {
    const screenData = props?.screenData
    const divRef = useRef<HTMLDivElement>(null)
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const [isShow, setShow] = useState(true)

    useEffect(() => {
        if (divRef.current) {
            props.setYPos(divRef.current.offsetTop)
        }
    }, [props])

    const convertItems = (screen_item_detail: any) => {
        let itemArr = []
        if (screen_item_detail && screen_item_detail.image_urls?.length > 0) {
            for (const url of screen_item_detail.image_urls) {
                itemArr.push({url: url, type: 'image'})
            }
        }
        if (screen_item_detail.video_url !== undefined && screen_item_detail.video_url !== null && screen_item_detail.video_url !== '') {
            itemArr.splice(0, 0, {url: screen_item_detail.video_url, type: 'video'})
        }
        return itemArr
    }
    const getName = (item: any) => {
        let name = ""
        if (item) {
            if (window.innerWidth <= 768) {
                name = item.mobile_name
            } else {
                name = item.desktop_name
            }
        }
        return name
    }
    const getDescription = (item: any) => {
        let description = ""
        if (item) {
            if (window.innerWidth <= 768) {
                description = item.mobile_description
            } else {
                description = item.desktop_description
            }
        }
        return description
    }

    return (
        <>

            <div className={`${styles.componentContainer} container`} ref={divRef}>

                <Swiper
                    direction={'horizontal'}
                    slidesPerView={1}
                    spaceBetween={100}
                    modules={[Thumbs]}
                    allowTouchMove={false}
                    onSwiper={props?.setSwiperRef}
                >
                    {
                        screenData && screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                        screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                            return (
                                <SwiperSlide className={styles.slideContainer}
                                             key={index}>
                                    <div className={`d-flex justify-content-center`}>
                                        <div className={styles.titleContainer}>
                                            <TitleName title={getName(screen_item_detail)}/>
                                        </div>
                                    </div>
                                    <div className={styles.fluidContainer}>
                                        <div className={styles.leftContainer}>
                                            <div className={styles.contentContainer}>
                                                {

                                                    getDescription(screen_item_detail)?.split(";")?.map((item: string, item_index: number) => {
                                                        return (
                                                            <span key={`item_${item_index}`}>{item}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.rightContainer}>
                                            <div className={styles.navBtn}>
                                                <div className={`${styles.prev} background-image`}
                                                     style={{backgroundImage: `url("${IconPrev}")`}}
                                                     ref={navigationPrevRef}>
                                                </div>
                                                <div className={`${styles.next} background-image`}
                                                     style={{backgroundImage: `url("${IconNext}")`}}
                                                     ref={navigationNextRef}>
                                                </div>
                                            </div>
                                            <Swiper
                                                className={`${styles.topSlider} productionContentSlider`}
                                                direction={'horizontal'}
                                                slidesPerView={1}
                                                navigation={{
                                                    prevEl: navigationPrevRef.current,
                                                    nextEl: navigationNextRef.current
                                                }}
                                                autoplay={{
                                                    delay: 5000,
                                                    disableOnInteraction: true,
                                                }}
                                                pagination={{dynamicBullets: true}}
                                                watchSlidesProgress={true}
                                                modules={[Navigation, Thumbs, Pagination]}
                                                onInit={(swiper: any) => {
                                                    const a: any = swiper.params.navigation
                                                    a.prevEl = navigationPrevRef.current
                                                    a.nextEl = navigationNextRef.current
                                                    swiper.navigation.destroy()
                                                    swiper.navigation.init()
                                                    swiper.navigation.update()
                                                }}
                                                breakpoints={{
                                                    768: {
                                                        pagination: false
                                                    }
                                                }}
                                            >
                                                {
                                                    screen_item_detail && screen_item_detail.image_urls && screen_item_detail.image_urls?.length > 0 &&
                                                    convertItems(screen_item_detail).map((item: any, item_index: number) => {
                                                        return (
                                                            <SwiperSlide className={styles.imageContainer}
                                                                         key={`url_${item_index}`}>
                                                                {
                                                                    item.type === 'image' &&
                                                                    <div
                                                                        className={`background-image ${styles.image}`}
                                                                        style={{backgroundImage: `url('${item.url}')`}}
                                                                    />
                                                                }
                                                                {
                                                                    item.type === 'video' &&
                                                                    <div className={styles.videoContainer}>
                                                                        <video src={item.url} autoPlay={false} muted={true} id={`video_${index}`} onPause={() => {setShow(true)}}
                                                                               onClick={() => {
                                                                                   const video = document.getElementById(`video_${index}`) as HTMLVideoElement | null
                                                                                   if (video !== undefined && video !== null) {
                                                                                       if (video.paused) {
                                                                                           video.play()
                                                                                           setShow(false)
                                                                                       } else {
                                                                                           video.pause()
                                                                                           setShow(true)
                                                                                       }
                                                                                   }
                                                                               }}
                                                                        />
                                                                        <img className={`${styles.icPlay} ${isShow ? '' : styles.hide}`} src={icPlayVideo} alt={''} onClick={() => {
                                                                            const video = document.getElementById(`video_${index}`) as HTMLVideoElement | null
                                                                            if (video !== undefined && video !== null) {
                                                                                if (video.paused) {
                                                                                    video.play()
                                                                                    setShow(false)
                                                                                } else {
                                                                                    video.pause()
                                                                                    setShow(true)
                                                                                }
                                                                            }
                                                                        }}/>
                                                                    </div>
                                                                }
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </>
    )
}
export default ContentSlider