import React from 'react'
import styles from './styles/content-items-phone.module.scss'

const ContentItemsPhone = (props: any) => {
    const screenData = props?.screenData?props?.screenData:""
    const getImage = (item: any) => {
        let url = ""
        if (item) {
            if (window.innerWidth <= 768) {
                url = item.desktop_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }
    return (
        <>
            <div className={styles.contentItemsContainer}>
                <div className={`${styles.contentItemsImage} align-items-center justify-content-center `}>
                    <div className={`background-image ${styles.image}`}
                         style={{backgroundImage: `url('${getImage(screenData)?getImage(screenData):""}')`}}/>
                </div>
                <div className={styles.contentItemsImageLine}/>
                <div className={styles.contentItemsImageInfo}>
                    <h2 className={styles.contentItemsImageInfoTitle}>{screenData?.name}</h2>
                    {
                        screenData &&
                        screenData.titles?.length > 0 && screenData.contents?.length > 0 &&
                        screenData.titles?.map((title: string, index: number) => {
                            return (
                                <span className={styles.contentItemsImageInfoDes}
                                      key={index}>{title}: {screenData.contents[index]}</span>
                            )
                        })
                    }
                </div>
            </div>
            <div className={styles.contentItemsImageContact}>
                <button className={styles.contentItemsImageContactAction} onClick={() => props?.handleToggleModal(screenData?.name)}>Liên Hệ</button>
            </div>
        </>
    )
}
export default ContentItemsPhone