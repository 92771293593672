import React from 'react'
import styles from './styles/planting-area.module.scss'
import PlantingInfo from "pages/Home/components/PlantingArea/components/PlantingInfo/PlantingInfo"
import TitleName from "components/TitleName/TitleName";

const PlantingArea = (props: any) => {
    return (
        <div className={`container`}>
            <div className={`background-image ${styles.contentTitle}`}>
                {
                    props?.plantingArea && <TitleName title={props?.plantingArea?.name}/>
                }
            </div>
            <div className={styles.containerPlanting}>
                <div className={styles.containerPlantingImg}>
                    <div className={`background-image ${styles.Image}`}
                         style={{backgroundImage: `url('${props?.plantingArea?.desktop_url}')`}}/>
                </div>
                <div className={styles.landContent}>
                    <PlantingInfo plantingArea={props?.plantingArea}/>
                </div>
            </div>
        </div>
    )
}
export default PlantingArea;