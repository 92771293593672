// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import HomePageReducer from './home-page'
import HeaderReducer from './header'
const rootReducer = combineReducers({
    HomePageReducer,
    HeaderReducer
})

export default rootReducer
