import React, {useEffect} from 'react'
import styles from './styles/modal.module.scss'

const Modal = (props: any) => {
    const modalRef = React.useRef(null)
    /**
     * Hook that alerts clicks outside of the passed ref
     */
    const useOutsideAlerter = (ref: any) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.handleToggleModal()
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    useOutsideAlerter(modalRef)
    /**
     * Component that alerts if you click outside of it
     */
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent} ref={modalRef}>
                {props?.children}
            </div>
        </div>
    );
}
export default Modal