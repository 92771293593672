import React, {useState} from "react"
import styles from "./styles/product-detail.module.scss"
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";

const ProductDetail = (props: any) => {
    const screenData = props?.screenData
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()
    return (
        <>
            <div className={styles.component}>
                <Swiper
                    pagination={false}
                    modules={[Pagination, Thumbs]}
                    className={'ProductSwiper'}
                    thumbs={{swiper: thumbsSwiper}}
                >
                    {
                        screenData &&
                        screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                        screenData.screen_item_details?.map((screen_item_detail: any, detail_index: number) => {
                            return (
                                <SwiperSlide key={detail_index}>
                                    <div className={styles.productImageContainer}>
                                        <div className={styles.imageSizing}>
                                            <div
                                                className={`background-image ${styles.productImage}`}
                                                style={{backgroundImage: `url('${screen_item_detail.desktop_url}')`}}/>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })

                    }
                </Swiper>
                <div className={styles.productListContainer}>
                    <div className={styles.subContent}>
                        <span>{screenData?.desktop_description}</span>
                    </div>
                    <Swiper className={`productListSwiper ${styles.productList}`}
                            modules={[Navigation, Thumbs]}
                            slidesPerView={2}
                            spaceBetween={30}
                            navigation={false}
                            grabCursor={true}
                            watchSlidesProgress
                            onSwiper={setThumbsSwiper}
                            breakpoints={{
                                768: {
                                    slidesPerView: 5
                                }
                            }}
                    >
                        {
                            screenData &&
                            screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                            screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                                return (
                                    <SwiperSlide className={styles.productContainer} key={index}>
                                        <div className={`background-image ${styles.image}`} style={{backgroundImage: `url('${screen_item_detail?.image_url}')`}}/>
                                        <span className={styles.productTitle}>{screen_item_detail.title_one}</span>
                                        {/*<span className={styles.productDescription}>{screen_item_detail.title_one}</span>*/}
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </>
    )
}
export default ProductDetail