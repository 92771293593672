import React from 'react'
import styles from './styles/value-coffee.module.scss'
import imgBackgroundValue from 'assets/images/home-value-background-number.png'
import TitleName from "components/TitleName/TitleName"
import imgBackGroundValueCofee from 'assets/images/home-value-background.png'
const ValueCoffee=(props: any)=>{
    return(
        <div className={`${styles.containerValueCoffee}`}>
            <div className={styles.valueCoffeeTitle}>
                <div>
                    <TitleName title={"Giá trị"}/>
                </div>
            </div>
            <div className={`${styles.valueCoffee} background-image`} style={{backgroundImage: `url('${imgBackGroundValueCofee}')`}}>
                    <div className={styles.valueCoffeeContainerLeft}>
                        {
                            props?.valueCoffee?.screen_item_details && props?.valueCoffee?.screen_item_details.length > 0 &&
                            props?.valueCoffee?.screen_item_details.map((item: any, index:number) => {
                            return (
                                <div className={styles.containerItem} key={index}>
                                    <div className={styles.containerContent}>
                                        <div className={styles.containerNumber}>
                                            <div className={`background-image ${styles.backgroundValue}`} style={{backgroundImage: `url('${imgBackgroundValue}')`}}>
                                                <p className={styles.number}>0{index + 1}</p>
                                            </div>
                                        </div>
                                        <div className={styles.containerDescription}>
                                            <h3 className={styles.title}>
                                                {item?.desktop_name}
                                            </h3>
                                            <span className={styles.description}>
                                                {item?.desktop_description}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`${styles.imageItem}`} >
                                        <div className={`background-image ${styles.img}`} style={{backgroundImage: `url('${item?.image_url}')`}}/>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
            </div>

        </div>
    )
}

export default ValueCoffee