import React from "react";
import styles from './styles/content-desktop.module.scss'
import ContentItemsDesktop from "pages/CoffeeBean/components/Content/ContentDesktop/components/ContentItemsDesktop/ContentItemsDesktop";

const ContentDesktop = (props: any) => {
    const screenData = props?.screenData
    return (
        <div className={`${styles.content}`}>
            <div className={`${styles.contentTitle} justify-content-center align-items-center`}>
                <h2>CÀ PHÊ NHÂN</h2>
            </div>
            <div className={styles.contentContent}>
                <div className={`${styles.contentContentRobusta}`}>
                    {
                        screenData && screenData.screen_item_details?.length > 0 &&
                        screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                            return (
                                <>
                                    {
                                        screen_item_detail.type === "robusta" &&
                                        <div className={`${styles.contentContentRobustaItem}`} key={`robusta_${index}`}>
                                            <ContentItemsDesktop screenData={screen_item_detail} handleToggleModal={props?.handleToggleModal()}/>
                                        </div>
                                    }
                                </>

                            )
                        })
                    }
                </div>
                <div className={`${styles.contentContentArabica}`}>
                    {
                        screenData && screenData.screen_item_details?.length > 0 &&
                        screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                            return (
                                <>
                                    {
                                        screen_item_detail.type === "arabica" &&
                                        <div className={`${styles.contentContentArabicaItem}`} key={`arabica_${index}`}>
                                            <ContentItemsDesktop screenData={screen_item_detail} handleToggleModal={props?.handleToggleModal()}/>
                                        </div>
                                    }
                                </>

                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ContentDesktop;