
import styles from "./styles/banner-component.module.scss"
const BannerComponent = (props: any) => {
    const screenData = props?.screenData
    
    const getImage = (item: any) => {
        let url = ''
        if (screenData) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    return (
        <div className={`${styles.componentContainer} container`}>
            <div className={`background-image ${styles.bannerImage}`} style={{backgroundImage: `url('${getImage(screenData)}')`}} />
        </div>
    )
}
export default BannerComponent