import React, {useState} from "react"
import styles from './styles/factory.module.scss'
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import iconPlayVideo from 'assets/icons/ic-home-factory-play.svg'

const Factory = (props: any) => {
    const [isPlayVideo, setIsPlayVideo] = useState(false)

    function PlayVideo(index: number) {
        if (!isPlayVideo) {
            // @ts-ignore
            document.getElementById(`mediaVideo${index}`).play()
            setIsPlayVideo(true)
        } else {
            // @ts-ignore
            document.getElementById(`mediaVideo${index}`).pause()
            setIsPlayVideo(false)
        }

    }

    return (
        <>
            {
                props?.factory && props?.factory?.screen_item_details && props?.factory?.screen_item_details.length > 0 &&
                props?.factory?.screen_item_details?.map((item: any, index: number) => {
                    return (
                        <div className={styles.factory} key={index}>
                            {
                                props?.factory?.type !== 'video' &&
                                <Swiper>
                                    {
                                        item?.image_urls?.map((item_image: any, index_image: number) => {
                                            return (
                                                <SwiperSlide key={index_image}>
                                                    {
                                                        <div className={styles.containerMedia}>
                                                            <div className={`background-image ${styles.img}`}
                                                                 style={{backgroundImage: `url('${item_image}')`}}/>
                                                        </div>
                                                    }
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            }
                            {
                                props?.factory?.type === 'video' &&
                                <div className={styles.containerMedia}>
                                    {
                                        !isPlayVideo &&
                                        <div className={`background-image ${styles.icPlay}`}
                                             style={{backgroundImage: `url('${iconPlayVideo}')`}} onClick={() => {
                                            PlayVideo(index)
                                        }}/>
                                    }
                                    <div className={styles.containerVideo} onClick={() => {
                                        PlayVideo(index)
                                    }}>
                                        <video src={item?.video_url} muted={true} controls={false} loop
                                               className={styles.video} id={`mediaVideo${index}`}/>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </>

    )
}
export default Factory