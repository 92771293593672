import React, {useEffect} from 'react'
import Page from "components/Page/Page";
//component
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import PartnerTitle from "pages/Partner/components/partner-title/PartnerTitle";
import PartnerContainer from "pages/Partner/components/partner-container/PartnerContainer";
import {useDispatch, useSelector} from "react-redux";

import {getScreenData} from "../../redux/actions/home-page";
import {PAGE_ENUM} from "../../enum/page-constrant";
const Partner=()=>{
    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)

    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.partner,PAGE_ENUM.website))
    }, [dispatch])

    const getScreenItem = (screen_item: number) => {
        const arr = store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screen_item}`)
        return arr
    }
    return(store?.homeScreen !== undefined && store?.homeScreen !== null ?
        <>
            <Page isHiddenFooter={false} >
                <Breadcrumb title={'Đối tác'}/>
                <PartnerTitle/>
                <PartnerContainer screenData={getScreenItem(1)}/>
            </Page>
        </> : <></>

    )
}
export default Partner