
import React, {useEffect} from 'react'
import Page from "components/Page/Page"
import {useDispatch, useSelector} from "react-redux"
import {PAGE_ENUM} from "../../../enum/page-constrant"
import {getScreenData} from "../../../redux/actions/home-page"
import LandExComponent from "pages/Extension/LandExtension/components/LandExt/LandExtComponent";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
const LandExtension =()=>{
    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)

    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.land_extension, PAGE_ENUM.website))
    }, [dispatch])

    const getScreenItem = (screen_item: number) => {
        const arr = store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screen_item}`)
        return arr
    }

    return (store?.homeScreen !== undefined && store?.homeScreen !== null ?
        <>
            <Page>
                <Breadcrumb title={'Vùng trồng'}/>
                <LandExComponent screenData={getScreenItem(1)}/>
            </Page>
        </> : <></>
    )
}
export default LandExtension