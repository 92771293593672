import React, {useEffect, useState} from 'react'
import './styles/slide-introduce.module.scss'
import SwiperCore, {Navigation, Pagination} from 'swiper'
import SliderImage from "../SliderImage/SliderImage"
import TitleName from "components/TitleName/TitleName"
//Swiper
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './styles/slide-introduce.module.scss'

//image
import bgIntroduce from '../../../../../../assets/images/home-background-introduce.jpg'
import icNext from 'assets/icons/ic-home-previous.svg'
import icPrevious from 'assets/icons/ic-home-next.svg'
SwiperCore.use([Navigation, Pagination])
function SliderIntroduce(props: any) {
    const [screenData, setScreenData] = useState(props?.screenData)
    const [sliderIndex, setSliderIndex] = useState(0)
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: any) {
            return "<span class=" + className + "></span>"
        }
    }
    useEffect(() => {
        setScreenData(props.screenData)
    },[props.screenData])

    const handleChangeIndex = (index: number) => {
        setSliderIndex(index)
    }

    return (
        <div className={styles.introduceHeader}>
            <div className={styles.introduceSlider}>
                <div className={styles.introduceSliderItem}>
                    <div className={styles.introduceSliderNavigation}>
                        <div ref={navigationNextRef}
                             className={`slideBtn ${styles.slidePrevBtn} `}>
                            <div className={` ${styles.slidePrevBtnIcons} background-image`} style={{backgroundImage:`url("${icPrevious}")`}}/>
                        </div>
                        <div className={styles.containerIndex}>
                            {sliderIndex + 1}
                        </div>
                        <div ref={navigationPrevRef}
                             className={`slideBtn ${styles.slideNextBtn} `}>
                            <div className={` ${styles.slideNextBtnIcons} background-image`} style={{backgroundImage:`url("${icNext}")`}}/>
                        </div>
                    </div>
                    <div className={`background-image ${styles.introduceHeaderContent}`} style={{backgroundImage: `url('${bgIntroduce}')`}}>
                        <div className={styles.introduceLeftContentHeader}>
                            <div>
                                <TitleName  title={screenData?.name}/>
                            </div>
                        </div>
                        <div className={styles.introduceLeftContentTitle}>
                            {screenData?.description?.split(';').map((value:string, index:number) => (
                                <p key={`description${index}`}>{value}</p>
                            ))}
                        </div>
                    </div>
                    <Swiper
                        direction={'vertical'}
                        slidesPerView={1}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current
                        }}
                        pagination={pagination}
                        modules={[Navigation, Pagination]}
                        className='sliderIntroduce'
                        onInit={(swiper) => {
                            const a: any = swiper.params.navigation
                            a.prevEl = navigationPrevRef.current
                            a.nextEl = navigationNextRef.current
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                        }}

                        breakpoints={{
                            280:
                                {
                                    direction: 'horizontal',
                                    // pagination: true
                                },
                            //
                            320:
                                {
                                direction: 'horizontal',
                                // pagination: true
                            },
                            769:
                                {
                                    direction: 'vertical',
                                }
                        }}
                        onSlideChange={(swiper) => {
                            handleChangeIndex(swiper?.activeIndex)
                        }}>
                        <>
                            {
                                screenData &&
                                screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                                screenData.screen_item_details?.map((screen_item_detail: any, index_detail: number) =>
                                    (
                                        <div className={styles.introduceSlider} key={index_detail} >
                                            <SwiperSlide key={index_detail}>
                                                <div className={styles.introduceLeft}>
                                                    <div className={styles.introduceLeftContent}>
                                                        <div
                                                            className={styles.introduceLeftContent_about}>
                                                            <h3 className={styles.introduceLeftContent_aboutTitle}>{screen_item_detail.title_one}</h3>
                                                            <p className={styles.introduceLeftContent_aboutContent}>{screen_item_detail.description}</p>

                                                            <div className={styles.introduceLeftContentAboutBtn}>

                                                               <button className={`on-desktop ${styles.btn}`} onClick={() => window.location.href = '/gioi-thieu'}>
                                                                   Xem Thêm
                                                               </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.introduceLeftContentImage}>
                                                        <SliderImage sliderData={screen_item_detail}/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </div>
                                    ))
                            }
                        </>


                    </Swiper>
                    <button className={`on-phone ${styles.btn}`} onClick={() => window.location.href = '/gioi-thieu'}>
                        Xem Thêm
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SliderIntroduce
