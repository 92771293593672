
import styles from './styles/breadcrumb.module.scss'

function Breadcrumb (props: any) {
    return (
        <div className={`container ${styles.containerBreadcrumb}`}>
            <p className={styles.content} onClick={() => window.location.href = '/'}><span>Trang chủ</span> / <span className={styles.breadcrumb}>{props?.title}</span></p>
        </div>
    )
}
export default Breadcrumb