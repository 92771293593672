import React from 'react'
import style from './styles/introduce-slider.module.scss'
import SliderIntroduce from "./components/SilderIntroduce/SliderIntroduce"

import bgIntroduce from '../../../../assets/images/home-background-introduce.jpg'
function IntroduceSlider(props: any) {

    return (
        <div className={`container`}>
            <div className={`background-image ${style.containerSlider}`} style={{backgroundImage: `url('${bgIntroduce}')`}}>
                <div className={`${style.sliderItem} ${style.sliderItemIntroduce}`}>
                    <SliderIntroduce screenData={props.screenData}/>
                </div>
            </div>
        </div>
    )
}

export default IntroduceSlider