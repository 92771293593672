import React from "react";
import styles from './styles/line-slogan.module.scss'
import logoImgDesktop from 'assets/images/zili-logo-desktop.webp'
import logoImgPhone from 'assets/images/zili-logo-phone.webp'

const LineSlogan=()=>{
    return(
        <div className={`container ${styles.line}`}>
            <span/>
            <img src={window.innerWidth > 768 ? logoImgDesktop : logoImgPhone} alt={""}/>
            <span/>
        </div>
    );
}
export default LineSlogan