import React from 'react'
import styles from "./styles/describe.module.scss";

const Describe = (props: any) => {
    const screenData = props?.screenData
    return (
        <div className={`${styles.content} container`}>
            <div className={styles.contentContainer}>
                    {screenData?.description.split(";")?.map((item: string, index: number) => {
                        return (
                             <span className={styles.contentContainerMain} key={index}>{item}</span>
                        )
                    })}
            </div>
        </div>
    )
}
export default Describe