import React from 'react'
import styles from './styles/title-name.module.scss'


const TitleName=(props:{title:string})=>{
    return (
        <div className={styles.contentTitle}>
            <span>{props.title} </span>
        </div>
    )
}
export default TitleName