import React from "react";


import styles from './styles/certification.module.scss'
import TitleName from "components/TitleName/TitleName";
const Certification=()=>{
    return (
        <div  className={styles.certification}>
            <div className={styles.certificationTitle}>
                <div>
                    <TitleName title={"Chứng Nhận"}/>
                </div>

            </div>
            <div className={styles.certificationMain}>

            </div>
        </div>
    )
}
export default Certification