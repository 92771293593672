import React from 'react'
// styles
import style from './styles/slider-image.module.scss'
//Swiper
import 'swiper/css'
import 'swiper/css/pagination'

function SliderImage(props: any) {
    return (
        <>
            {
                props?.sliderData &&
                    <div className={style.containerMain}>
                        {
                            props?.sliderData?.image_urls?.length > 0 &&
                            props?.sliderData?.image_urls?.map((url: string, index_image: number) =>
                                (
                                    <div className={style.containerImage} key={index_image}>
                                        <img src={url} alt=""/>
                                    </div>
                                )
                            )
                        }
                    </div>
            }
        </>
    )
}

export default SliderImage
