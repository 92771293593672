import {useEffect, useState} from "react"
import styles from "./styles/store-component.module.scss"
import imageLayout from 'assets/images/img-partner-wholesale.png'

const StoreComponent = (props: any) => {
    const [screenData, setScreenData] = useState(props?.screenData)

    useEffect(() => {
        setScreenData(props?.screenData)
    }, [props?.screenData])
    const getImage = (item: any) => {
        let url = ''
        if (screenData) {
            if (window.innerWidth <= 768) {
                url = item.desktop_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }
    return (
        <>
            <div className={styles.componentContainerBackground}/>
            <div className={`${styles.componentContainer} container`} id={`lam-dong`}>
                {
                    screenData && screenData.screen_item_details?.length > 0 &&
                    screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                        return (
                            <div className={`${styles.componentContainerMain}`} key={index}>
                                <div className={styles.componentContainerMainTop}>
                                    <div className={styles.content}>
                                        <span className={styles.contentTitle}>{screen_item_detail.name}</span>
                                        <div className={styles.descriptionContainer}>
                                            {
                                                screen_item_detail.description.split(";")?.map((item: string, desc_index: number) => {
                                                    return (
                                                        <span className={styles.contentNote}
                                                              key={`desc_${desc_index}`}>{item}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className={`${styles.contentImg}`}>
                                            <div className={`background-image ${styles.decorImg}`}
                                                 style={{backgroundImage: `url("${imageLayout}")`}}/>
                                        </div>
                                    </div>
                                    {
                                        window.innerWidth > 768 &&
                                        <div className={styles.mainImageContainer}>
                                            <div className={`${styles.image} background-image`}
                                                 style={{backgroundImage: `url("${getImage(screen_item_detail)}")`}}/>
                                        </div>
                                    }
                                </div>
                                {
                                    window.innerWidth <= 768 &&
                                    <div className={styles.mainImageContainer}>
                                        <div className={`${styles.image} background-image`}
                                             style={{backgroundImage: `url("${getImage(screen_item_detail)}")`}}/>
                                    </div>
                                }
                                <div className={styles.componentContainerMainBottom}>
                                    {
                                        screen_item_detail && screen_item_detail.image_urls?.length > 0 &&
                                        screen_item_detail.image_urls?.map((url: string, url_index: number) => {
                                            return (
                                                <div className={styles.imageContainer} key={`url_${url_index}`}>
                                                    <div
                                                        className={`${styles.componentContainerMainBottomItem} background-image`}
                                                        style={{backgroundImage: `url("${url}")`}}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
export default StoreComponent