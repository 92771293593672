import React from 'react'

import styles from './styles/layout-image.module.scss'
import imgLayoutHead from 'assets/images/img-partner-wholesale.png'

const LayoutImage = (props: any) => {
    const screenData = props?.screenData

    const getImage = (item: any) => {
        let url = ''
        if (screenData) {
            if (window.innerWidth <= 768) {
                url = item.desktop_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    return (
        <>
            <div className={styles.componentContainer}/>
            <div className={`container`} id={`ho-chi-minh`}>
                <div className={`${styles.layout} justify-content-center align-items-center`}>
                    {
                        screenData && screenData.screen_item_details?.length > 0 &&
                        screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                            return (
                                <div className={styles.layoutContainer} key={index}>
                                    <div className={styles.layoutContainerHead}>
                                        <div className={styles.layoutContainerHeadImg}>
                                            <div className={`${styles.headImg} background-image`}
                                                 style={{backgroundImage: `url('${getImage(screen_item_detail)}')`}}>

                                            </div>
                                        </div>

                                        <div className={`${styles.layoutContainerHeadContent}`}>
                                            <div className={`${styles.layoutContainerHeadContentMain}`}>
                                            <span
                                                className={styles.layoutContainerHeadContentMainTitle}>{screen_item_detail.name}</span>
                                                {
                                                    screen_item_detail.description.split(";")?.map((item: string, desc_index: number) => {
                                                        return (
                                                            <span className={styles.layoutContainerHeadContentMainContent}
                                                                  key={`desc_${desc_index}`}>{item}</span>
                                                        )
                                                    })
                                                }
                                                <div
                                                    className={`${styles.layoutContainerHeadContentMainImg}  background-image`}
                                                >
                                                    <div className={`background-image ${styles.decorImg}`}
                                                         style={{backgroundImage: `url('${imgLayoutHead}')`}}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.layoutContainerBottom}>
                                        <div className={styles.layoutContainerBottomGroup3}>
                                            {
                                                screen_item_detail && screen_item_detail.image_urls?.length > 0 &&
                                                screen_item_detail.image_urls.slice(0, 3)?.map((value: string, index: number) => {
                                                    return <div className={`${styles.layoutContainerBottomImg} 
                                                                background-image`}
                                                                style={{backgroundImage: `url("${value}")`}}
                                                                key={`${index}_img`}>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className={styles.layoutContainerBottomGroup2}>
                                            {
                                                screen_item_detail && screen_item_detail.image_urls?.length >= 3 &&
                                                screen_item_detail.image_urls?.slice(3, 5)?.map((value: string, index: number) => {
                                                    return <div
                                                        className={`background-image ${styles[`layoutContainerBottomImg${index}`]}`}
                                                        style={{backgroundImage: `url("${value}")`}} key={`${index}_img`}>
                                                    </div>
                                                })}
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default LayoutImage