import {useDispatch, useSelector} from "react-redux"
import React, {useEffect} from "react"
import {useParams} from "react-router-dom"
import Breadcrumb from "components/Breadcrumb/Breadcrumb"
import Page from "components/Page/Page"
import ProductDetail from "pages/Product/components/ProductInfo/components/ProductDetail/ProductDetail"
import {getScreenData} from "../../../../redux/actions/home-page"
import {PAGE_ENUM} from "../../../../enum/page-constrant"
import LineSlogan from "components/LineSlogan/LineSlogan"
import ProductIntroduce from "pages/Product/components/ProductInfo/components/ProductIntroduce/ProductIntroduce"

const ProductInfo = () => {
    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)
    const {id} = useParams()
    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.product, PAGE_ENUM.website))
    }, [dispatch])
    const screenData = () => {
        const arr = store?.homeScreen?.screen_landings?.filter((item: any) => item.id === parseInt(typeof id === "string" ? id : ''))
        return arr ? Object.assign({}, ...arr) : {}
    }
    return (
        <>
            <Page isHiddenFooter={false}>
                <Breadcrumb title={'Sản phẩm'}/>
                <ProductDetail screenData={screenData()}/>
                <LineSlogan/>
                <ProductIntroduce screenData={screenData()}/>
            </Page>
        </>
    )
}
export default ProductInfo