import {useEffect, useState} from "react";
import styles from "./styles/top-banner-components.module.scss"

const TopBannerComponent = (props: any) => {
    const [screenData, setScreenData] = useState(props?.screenData)

    useEffect(() => {
        setScreenData(props?.screenData)
    }, [props?.screenData])

    const getImageURL = (item: any) => {
        let url = ''
        if (item) {
            if (window.innerWidth <= 768) {
                url = item.mobile_url
            } else {
                url = item.desktop_url
            }
        }
        return url
    }

    const getVideoURL = (item: any) => {
        let url: string = ''
        if (item) {
            url = item.video_url
        }
        return url
    }

    return (
        <>
            <div className={styles.componentContainer}>
                {
                    screenData?.type === "image" &&
                    <div className={`background-image ${styles.topBannerImage}`}
                         style={{backgroundImage: `url('${getImageURL(screenData)}')`}}/>
                }
                {
                    screenData?.type === "video" && getVideoURL(screenData) !== "" && getVideoURL(screenData) !== null &&
                    <div className={styles.videoContainer}>
                        <video className={styles.topBannerVideo} src={getVideoURL(screenData)} autoPlay={true}/>
                    </div>
                }
            </div>
        </>
    )
}

export default TopBannerComponent