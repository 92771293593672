import React, {useEffect} from "react";
import Page from "components/Page/Page";
import BannerComponent from "pages/Store/components/Banner/BannerComponent";
import {useDispatch, useSelector} from "react-redux";
import {PAGE_ENUM} from "../../enum/page-constrant";
import {getScreenData} from "../../redux/actions/home-page";
import StoreComponent from "pages/Store/components/Store/StoreComponent";
import LineSlogan from "components/LineSlogan/LineSlogan";
import AddressComponent from "pages/Store/components/Address/AddressComponent";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import Describe from "pages/Store/components/Describe/Describe";
import LayoutImage from "pages/Store/components/LayoutImage/LayoutImage";

const Store = () =>{
    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)

    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.store, PAGE_ENUM.website))
    }, [dispatch])

    const getScreenItem = (screen_item: number) => {
        const arr = store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screen_item}`)
        return arr
    }
    return (store?.homeScreen !== undefined && store?.homeScreen !== null && getScreenItem.length>0 ?
        <>
            <Page>
                <Breadcrumb title={'Cửa hàng'}/>
                <BannerComponent screenData={getScreenItem(1)}/>
                <Describe screenData={getScreenItem(2)}/>
                <StoreComponent screenData={getScreenItem(4)}/>
                <LayoutImage  screenData={getScreenItem(3)}/>
                <LineSlogan />
                <AddressComponent />
            </Page>
        </> : <></>
    )
}
export default Store;