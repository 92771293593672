import styles from "./styles/introduce-ext-component.module.scss"
import publicStyle from "../../styles/introduce-extension.module.scss"
import TitleName from "components/TitleName/TitleName";
const IntroduceExtComponent = (props: any) => {
    const screenData = props?.screenData

    return (
        <div className={`${styles.componentContainer} `}>
            <div className={publicStyle.titleContainer}>
                <div>
                    <TitleName title={screenData?.desktop_name}/>
                </div>

            </div>
            <div className={publicStyle.container}>
                <div className={styles.imageContainer}>
                    <div className={`background-image ${styles.leftImage}`} style={{backgroundImage: `url('${screenData?.desktop_url}')`}}/>
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.mainDescription}>
                    {
                        screenData &&
                        screenData.desktop_description && screenData.desktop_description?.length > 0 &&
                            screenData.desktop_description?.split(";").map((item: string, description_index: number) => {
                                return (
                                    <span className={styles.description} key={description_index}>{item}</span>
                                )
                            })
                    }
                    </div>
                    <div className={styles.contentWrapper}>
                        {
                            screenData &&
                            screenData.screen_item_details && screenData.screen_item_details?.length > 0 &&
                            screenData.screen_item_details?.map((screen_item_detail: any, index: number) => {
                                return (
                                    <div className={styles.content} key={index}>
                                        <span className={styles.title}>{screen_item_detail?.desktop_name}</span>
                                        {
                                            screen_item_detail?.desktop_description &&
                                            screen_item_detail?.desktop_description?.split(";")?.map((description: string, des_index: number) => {
                                                return (
                                                    <div className={styles.description} key={`des_${des_index}`}>{description}</div>
                                                )
                                            })
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IntroduceExtComponent