import React from "react"

import styles from './styles/partner-container.module.scss'
import PartnerInformation from "pages/Partner/components/partner-information/PartnerInformation"
import PartnerForm from "pages/Partner/components/partner-form/PartnerForm"
const PartnerContainer=(props: any)=>{
    return(
        <div className={`container d-flex justify-content-center ${styles.partContainer}`}>
                <div className={styles.partContainerContent}>
                    <div className={styles.partContainerContentInfo}>
                        <PartnerInformation screenData={props?.screenData}/>
                    </div>
                    <div className={styles.partContainerContentLine}>
                    </div>
                    <div className={styles.partContainerContentForm}>
                        <PartnerForm/>
                    </div>
                </div>
        </div>
    )
}

export default PartnerContainer