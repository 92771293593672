import React, {useEffect, useState} from 'react'
import Page from "components/Page/Page";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ContentDesktop from "pages/CoffeeBean/components/Content/ContentDesktop/ContentDesktop";
import Image from "pages/CoffeeBean/components/Images/Images";
import LineSlogan from "components/LineSlogan/LineSlogan";
import Logo from "pages/CoffeeBean/components/Logo/Logo";
import Slider from "pages/CoffeeBean/components/Slider/Slider";
import Describe from "pages/CoffeeBean/components/Describe/Describe";
import {useDispatch, useSelector} from "react-redux";
import {PAGE_ENUM} from "../../enum/page-constrant";
import {getScreenData} from "../../redux/actions/home-page";
import ContentPhone from "pages/CoffeeBean/components/Content/ContentPhone/ContentPhone";
import PartnerForm from "pages/CoffeeBean/components/PartnerForm/PartnerForm";
import Modal from "components/Modal/Modal";

const CoffeeBean = () => {

    const dispatch = useDispatch()
    const store = useSelector((state: any) => state.HomePageReducer)
    const [isShow, setShow] = useState(false)
    const [modalData, setModalData] = useState('')
    useEffect(() => {
        dispatch(getScreenData(PAGE_ENUM.green_bean, PAGE_ENUM.website))
    }, [dispatch])

    const handleToggleModal = (name: string) => {
        setShow(!isShow)
        setModalData(name)
    }
    const getScreenItem = (screenNumber: number | undefined) => {
        return store?.homeScreen?.screen_landings?.find((item: any) => item?.screen === `screen_item_${screenNumber}`)
    }

    return (
        <>
            <Page isHiddenFooter={false}>
                <Breadcrumb title={"Cà phê nhân xanh"}/>
                <Image screenData={getScreenItem(1)}/>
                <Describe screenData={getScreenItem(2)}/>
                {
                    window.innerWidth <= 768 &&
                    <ContentPhone screenData={getScreenItem(3)} handleToggleModal={() => handleToggleModal}/>
                }
                {
                    window.innerWidth > 768 &&
                    <ContentDesktop screenData={getScreenItem(3)} handleToggleModal={() => handleToggleModal}/>
                }
                <LineSlogan/>
                <Logo screenData={getScreenItem(4)}/>
                <Slider screenData={getScreenItem(5)}/>
                {
                    isShow &&
                        <Modal handleToggleModal={handleToggleModal}>
                            <PartnerForm handleToggleModal={handleToggleModal} productName={modalData}/>
                        </Modal>
                }
            </Page>
        </>
    )
}
export default CoffeeBean