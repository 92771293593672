import React from 'react'
import styles from './styles/modal.module.scss'

const Modal=({handleCloseModal, message}:any)=>{
    return(
        <>
                <div className={styles.darkBG} />
                <div className={styles.centered}>
                    <div className={styles.modal}>
                        <div className={styles.modalHeader}>
                            <h5 className={styles.heading}>Thông báo</h5>
                        </div>
                        {/*<button className={styles.closeBtn} >*/}
                        {/*    /!*<RiCloseLine style={{ marginBottom: "-3px" }} />*!/*/}
                        {/*</button>*/}
                        {
                            message &&
                            <div className={styles.modalContent}>
                                {message}
                            </div>
                        }
                        {
                            !message &&
                            <div className={styles.modalContent}>
                                Cảm ơn bạn đã để lại thông tin !!!
                            </div>
                        }

                        <div className={styles.modalActions}>
                            <div className={styles.actionsContainer}>
                                <button
                                    className={styles.cancelBtn}
                                    onClick={handleCloseModal}>
                                  Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}
export default Modal