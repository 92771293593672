import React, {useState} from 'react'
import style from './styles/header.module.scss'

//icon
import icLogo from 'assets/icons/ic-logo-white.svg'
import icDropdown from '../../../../assets/icons/ic-dropdown-white.svg'
import NavigationOnPhone from "components/Page/components/Header/components/NavigaionOnPhone/NavigationOnPhone"

function Header() {

    const menuList = [
        {
            label: 'Trang chủ',
            url: '/'
        },
        {
            label: 'Sản Xuất',
            url: '/san-xuat',
            sub_title_list: [
                {
                    content: '',
                    image_url: 'https://media.zili.vn/images/so-che-lam-sach.webp',
                    url: '/san-xuat',

                },
                {
                    content: '',
                    image_url: 'https://media.zili.vn/images/phoi.webp',
                    url: '/san-xuat',

                },
                {
                    content: '',
                    image_url: 'https://media.zili.vn/images/phan-loi.webp',
                    url: '/san-xuat',

                }
            ]
        },
        {
            label: 'Sản Phẩm',
            url: '/san-pham',
            is_dropdown: true,
            sub_title_list: [
                {
                    content: 'DÒNG CAO CẤP TRẢI NGHIỆM',
                    image_url: 'https://media.zili.vn/images/san-pham-trai-nghiem.webp',
                    url: '/san-pham#trai-nghiem'
                },
                {
                    content: 'DÒNG CAO CẤP TRUYỀN THỐNG',
                    image_url: 'https://media.zili.vn/images/san-pham-truyen-thong.webp',
                    url: '/san-pham#truyen-thong'
                },
                {
                    content: 'DÒNG TIÊU CHUẨN',
                    image_url: 'https://media.zili.vn/file/sp-truyen-thong.webp',
                    url: '/san-pham#tieu-chuan'
                },
                {
                    content: 'CÀ PHÊ PHIN NHANH',
                    image_url: 'https://media.zili.vn/images/san-pham-phin-nhanh.webp',
                    url: '/san-pham#phin-nhanh'
                },
                {
                    content: 'CÀ PHÊ TIỆN DỤNG',
                    image_url: 'https://media.zili.vn/images/san-pham-tien-dung.webp',
                    url: '/san-pham#tien-dung'
                }
            ]
        },
        {
            label: 'Nhân xanh',
            url: '/ca-phe-nhan'
        },
        {
            label: 'Đối tác',
            url: '/doi-tac'
        },
        {
            label: 'Cửa hàng',
            url: '/cua-hang',
            is_dropdown: true,
            sub_title_list: [
                {
                    content: '42 Đường số 2, Khu  Cityland, P.7, Quận Gò Vấp, TP.Hồ Chí Minh',
                    image_url: 'https://media.zili.vn/images/cua-hang-hcm.webp',
                    url: '/cua-hang#ho-chi-minh',

                },
                {
                    content: 'Số 206-207, Quốc Lộ 20, Thôn 8, Xã Liên Đầm, Huyện Di Linh, Tỉnh Lâm Đồng',
                    image_url: 'https://media.zili.vn/images/cua-hang-lam-dong.webp',
                    url: '/cua-hang#lam-dong'
                }
            ]
        },
        {
            label: 'Mua Online',
            type: 'mua-hang',
            is_dropdown: true,
            sub_title_list: [
                {
                    content: 'Website Chính Hãng',
                    url: 'https://zilicoffee.vn'
                },
                {
                    content: 'Shopee',
                    url: 'https://shopee.vn/zilicoffeevietnam'
                },
                {
                    content: 'Lazada',
                    url: 'https://www.lazada.vn/shop/zili-coffee'
                }
            ]
        }
    ]
    const [isShowModalNavigation, setIsShowModalNavigation] = useState(false)
    const [headerContent] = useState(menuList)

    return (
        <>
            <div className={`on-phone`} style={{paddingTop: '68px'}}/>
            <header id={'header'}
                    className={`${style.containerHeader} ${isShowModalNavigation ? style.isShowNavigation : ''}`}>
                <div className={`container ${style.header}`}>
                    <div className={`background-image ${style.headerLogo}`}
                         style={{backgroundImage: `url('${icLogo}')`}} onClick={() => window.location.href = '/'}/>
                    <div className={`on-desktop ${style.headerMenu}`}>
                        <div className={`${style.containerNavigation}`}>
                            {
                                headerContent.map((menu: any, index: number) => {
                                    return (
                                        <div
                                            className={`${style.containerNavLink} ${menu?.type === 'mua-hang' ? style.shoppingItem : ''}`}
                                            key={index}>
                                            <div
                                                className={`${style.navigationItem} ${window.location?.pathname === menu?.url ? style.navigationItemActive : ''}`}
                                                onClick={() => { if (menu.url) { window.location.href = menu.url}}}>
                                                {menu?.label}
                                            </div>
                                            {
                                                menu?.is_dropdown &&
                                                <>
                                                    <div className={`background-image ${style.icDropdown}`}
                                                         style={{backgroundImage: `url('${icDropdown}')`}}/>
                                                    {
                                                        menu?.type !== 'mua-hang' &&
                                                        <>
                                                            <div className={style.bgDropdown}/>
                                                            <div
                                                                className={` ${style.containerDropdown} ${menu?.url === '/san-pham' ? style.containerDropdownBuyProduct : ''}`}>
                                                                {
                                                                    menu?.sub_title_list && menu?.sub_title_list.length > 0 &&
                                                                    menu?.sub_title_list.map((item: any, index_list: number) => {
                                                                        return (
                                                                            <div key={index_list}
                                                                                 className={style.dropdown}>
                                                                                <div className={style.dropdownItem}
                                                                                     onClick={() => { if (item.url) { window.location.href = item.url}}}>
                                                                                    <div
                                                                                        className={`background-image ${style.image}`}
                                                                                        style={{backgroundImage: `url('${item?.image_url}')`}}/>
                                                                                    <p className={style.content}>{item?.content}</p>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        menu?.type === 'mua-hang' &&
                                                        <div className={style.containerShoppingDropdown}>
                                                            {
                                                                menu?.sub_title_list && menu?.sub_title_list.length > 0 &&
                                                                menu?.sub_title_list.map((item: any, index_list: number) => {
                                                                    return (
                                                                        <div key={`shopping_${index_list}`}
                                                                             className={style.dropdownShopping}>
                                                                            <div className={style.dropdownShoppingItem}
                                                                                 onClick={() => { if (item.url) { window.open(item.url, '_blank')}}}>
                                                                                <p className={style.content}>{item?.content}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={` ${style.headerBar}`}
                         onClick={() => setIsShowModalNavigation(!isShowModalNavigation)}>
                        <div className={`${style.headerBarIcon} ${isShowModalNavigation ? style.active : ''}`}>
                            <div className={`${style.bar} ${style.topBar}`}/>
                            <div className={`${style.bar} ${style.middleBar}`}/>
                            <div className={`${style.bar} ${style.bottomBar}`}/>
                        </div>
                    </div>
                </div>
            </header>
            <NavigationOnPhone isShowNavigation={isShowModalNavigation} menuList={headerContent}/>
        </>
    )
}

export default Header
